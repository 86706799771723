import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { resendInvite } from "../../../../../api/user";
import { DangerIcon, RefreshIcon, ValidIcon } from "../../../../../components/icons";
import Button from "../../../../../components/ui/button";
import Loader from "../../../../../components/ui/loader";
import Pill from "../../../../../components/ui/pill";
import Styled from "../../../../../components/ui/styled";
import { RequestStatus } from "../../../../../types/requests";
import { Container, IconContainer } from "./resend-invite.styles";

type Props = {
    email: string
}

const ResendInvite = ({ email }: Props) => {
    const [status, setStatus] = useState<RequestStatus>('IDLE');
    const [error, setError] = useState<boolean>(false);

    const handleOnClick = useCallback(() => {
        if (status === 'PENDING') return;

        setStatus('PENDING');
        setError(false);

        resendInvite(email).then(() => {
            setStatus('RESOLVED');
        }).catch(() => {
            setStatus('RESOLVED');
            setError(true);
        });


    }, [status, error]);

    const renderIcon = useCallback(() => {
        switch (status) {
            case 'IDLE':
                return <RefreshIcon />
            case 'PENDING':
                return <Loader size="small" />;
            case 'RESOLVED':
                return !error ? <ValidIcon /> : <RefreshIcon />
            default:
                return null
        }
    }, [status, error]);

    const isResolved = status === 'RESOLVED';
    const isPending = status === 'PENDING';

    const variant = isResolved && !error ? 'dark' : null;

    return <Container>
        {error && <Styled display="inline-flex" marginRight="0.5rem">
            <Pill variant="danger" size="small">
                <Styled marginRight="0.5rem" marginTop="0.1rem"><DangerIcon /></Styled>
                <FormattedMessage id="trainees-list:resendInvite_error" />
            </Pill>
        </Styled>}
        <Button variant={variant} disabled={isPending} size="small" onClick={handleOnClick}>
            <IconContainer>
                {renderIcon()}
            </IconContainer>
            <FormattedMessage id={`trainees-list:${isResolved && !error ? `email-sent` : `resend-invite`}`} />
        </Button>
    </Container>
}

export default ResendInvite;