export const speedBlockColors = ["#8D2260", "#641844", "#4D4D4D", "#A9501C", "#EE7127"];

export const TICK_INTERVAL = 1000;
export const TICK_TIMESCALE = 1000;

export const calculateTime = (currentIndex: number, stepInterval: number) => {
  if (currentIndex < 0) {
    return '0:00'
  }
  const currentTimeInSeconds = currentIndex * stepInterval / TICK_TIMESCALE; // Convert milliseconds to seconds
  const currentMinutes = Math.floor(currentTimeInSeconds / 60); // Calculate minutes
  const currentSeconds = Math.floor(currentTimeInSeconds % 60); // Calculate remaining seconds
  const currentTime = `${currentMinutes}:${currentSeconds.toString().padStart(2, '0')}`; // Format time as MM:SS
  return currentTime;
};

export const generateCircularSegments = (N: number, center: { lat: number, lon: number }, radius: number) => {
  const segments = [];
  const angleStep = (2 * Math.PI) / N; // Divide the circle into N segments

  let previousLat = center.lat + radius * Math.sin(0); // Start at angle 0
  let previousLon = center.lon + radius * Math.cos(0);

  for (let i = 1; i <= N; i++) {
    const angle = i * angleStep;
    const newLat = center.lat + radius * Math.sin(angle);
    const newLon = center.lon + radius * Math.cos(angle);

    segments.push({
      coords: [[previousLat, previousLon], [newLat, newLon]] as [number, number][],
      color: getRandomColor()
    });

    previousLat = newLat;
    previousLon = newLon;
  }

  return segments;
};

export const getRandomColor = () => speedBlockColors[Math.floor(Math.random() * speedBlockColors.length)];
