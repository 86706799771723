import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import { PageLayout, StyledHeatLogo, Title } from "../onboarding-styles";
import { useHistory } from "react-router-dom";
import { SuccessIcon } from "../../../components/icons";
import { ContentWrapper, Description, SuccessContainer } from "./success.styles";
import { PrimaryButton } from "../../shared/shared.styles";
import { useQuery, useQueryClient } from "react-query";
import { getWelcomeSurvey } from "../../../api/user";
import { WelcomeSurveyData } from "../survey/survey.types";
import { useEffect, useState } from "react";

const localeFn = (target: string) => import(`./../locale/${target.toLowerCase()}.json`);

const SurveySuccess = () => {
    const history = useHistory();

    const queryClient = useQueryClient();

    const [successPageButton, setSuccessPageButton] = useState<any>();

    const {
        data: welcomeSurveyData,
    } = useQuery<WelcomeSurveyData>(["getWelcomeSurvey"],() => getWelcomeSurvey(),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
        }
    );

    useEffect(() => {
        setSuccessPageButton(welcomeSurveyData?.successPageButton);
    }, [welcomeSurveyData])

    const redirectToOverview = async () => {

        /* this invalidates & refetches updated user data from api call getUser,
        so that when first time user gets redirected to Overview page,
        UserBadge component displays the updated data */
        await queryClient.invalidateQueries("get-user");
        await queryClient.refetchQueries("get-user");

        history.push(!!successPageButton ? `${successPageButton.redirectLink}` : `/overview`);
    }; 

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="privacy-page" fallback={null}>
            <PageLayout>

                <SuccessContainer minHeight>
                    <StyledHeatLogo />
                    
                    <ContentWrapper>
                        <SuccessIcon />
                        <Description><FormattedMessage id={'success:desc'} /></Description>
                        <PrimaryButton onClick={redirectToOverview} fullWidth>
                            {!!successPageButton ? (
                                successPageButton?.text
                                ) : (
                                <FormattedMessage id={'success:goto-overview'} />
                            )}
                        </PrimaryButton>
                    </ContentWrapper>

                </SuccessContainer>

            </PageLayout>
       </VRIntlProviderComponent>     
    )
}

export default SurveySuccess;