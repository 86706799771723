import styled from "styled-components";
import base from "../../themes/base/base";
import { HeatLogoForNavigation } from "../../components/icons";

export const StyledHeatLogo = styled(HeatLogoForNavigation)`
    width: 11.16631rem;
    height: 3rem;
    overflow: visible;
`;

interface ContainerStylingProps {
    minHeight?: boolean;
};

export const Container = styled.div<ContainerStylingProps>`
    display: flex;
    width: 45.5rem;
    min-height: 22rem;
    max-height: 43.75rem;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    border-radius: 0.5rem;
    background-color: ${base.colors.neutral.grey[800]};
    max-height: 58rem;
`;

export const PageLayout = styled.div`
    height: 100%;
    width: 100%;
    background: url('/assets/heat-onboarding-background.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.75rem;
`;

export const Title = styled.span`
    ${base.typography.extraLarge2Regular};  
    text-align: center;  
`;