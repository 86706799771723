import { CustomSlider, SurveySliderWrapperDiv } from "./slider.styles";
import { BottomSeparator, Label } from "../../survey.styles";
import { FormattedMessage } from "react-intl";
import { Controller } from "react-hook-form";
import { SurveySliderProps } from "./slider.types";

const SurveySlider = ({
    registeredField,
    control,
    options,
    step,
    min,
    max,
    label,
    labelFormattedMessage,
    labelMarginBottom,
    labelHeight
}: SurveySliderProps) => {

    //API response values take precedence over hardcoded translation values
    const labelText = label ? label : <FormattedMessage id={labelFormattedMessage} />;

    return (
        <SurveySliderWrapperDiv>
            <Label marginBottom={labelMarginBottom} height={labelHeight}>
                {labelText}
            </Label>
            <Controller
                control={control}
                name={registeredField}
                defaultValue={options[0].value}
                render = {({ field: { onChange, onBlur, value } }) => {
                    return (
                        <CustomSlider
                            value={value}
                            onChange={(e, newValue) => onChange(newValue)}
                            marks={options}
                            step={step}
                            min={min}
                            max={max}
                        />
                    )
                }}
            />
            <BottomSeparator />
        </SurveySliderWrapperDiv>
    );
};

export default SurveySlider;

/*  README
    1. SurveySlider component should always receive numeric values as options
     
    2. min, max in above case is optional 
*/