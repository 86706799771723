import styled, { css } from "styled-components";
import base from "../../../themes/base/base";

interface SortingFiltersWrapperStylingProps {
    isOpen: boolean
}

export const SortingFiltersWrapperDiv = styled.div<SortingFiltersWrapperStylingProps>`
    background-color: ${base.colors.neutral.grey[800]};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.4375rem 0.75rem;
    position: relative;
    border-radius: 0.125rem;
    border: 1px solid ${base.colors.neutral.grey[900]};

    &:hover {
        cursor: pointer;
        background-color: ${base.colors.neutral.grey[600]};
    }

    ${({ isOpen }) => isOpen && css`
        border: 1px solid ${base.colors.primary.orange[500]};
    `};

    @media (max-width: 1023px) {
        padding: 0;
    }
`;

export const TextDiv = styled.div`
    ${base.typography.smallRegular};
`;

export const PopUp = styled.div<{ width: string | number }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: calc(100% + 0.5rem);
    right: 0;
    width: ${({ width }) => width};
    border: 1px solid ${base.colors.neutral.grey[300]};
    border-radius: 2px;
    background-color: ${base.colors.neutral.grey[800]};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 9999;
`;

export const PopUpItem = styled.div<{ isSelected: boolean }>`
    ${base.typography.smallRegular};

    padding: 0.5rem 1.25rem 0.5rem 0.75rem;

    ${({ isSelected }) =>
        isSelected &&
        css`
            ${base.typography.smallMedium};
            color: ${base.colors.primary.orange[500]};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${base.colors.neutral.grey[600]};
    }
`;
