import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../providers/intl-provider";
import {
    Actions,
    ContentAndBackgroundContainer,
    Container,
    Description,
    LogoutButton,
    Title,
    ContentWrapper,
    RefreshButton,
    OverviewButton
} from "./generic-error";
import { Pages } from "../../../enums/pages";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const GenericError = () => {

    const { instance } = useMsal();

    const handleLogOut = useCallback(() => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }, [instance]);

    return <>
    <VRIntlProviderComponent 
        localeFn={localeFn}
        id="generic-error"
        fallback={null}
    >
        <Container>
            <ContentAndBackgroundContainer>
                <ContentWrapper>
                    <Title>
                        <FormattedMessage id="generic-error:message" />
                    </Title>
                    <Description>
                        <FormattedMessage id="generic-error:description" />
                    </Description>
                    <Actions>
                        <RefreshButton
                            onClick={() => window.location.reload()}
                            fullWidth
                        >
                            <FormattedMessage id="generic-error:refresh" />
                        </RefreshButton>
                        <OverviewButton
                            onClick={() => window.location.href = Pages.Overview}
                            fullWidth
                        >
                            <FormattedMessage id="generic-error:go-to-overview" />
                        </OverviewButton>
                        <LogoutButton
                            onClick={handleLogOut}
                            fullWidth
                        >
                            <FormattedMessage id="generic-error:log-out" />
                        </LogoutButton>
                    </Actions>
                </ContentWrapper>
            </ContentAndBackgroundContainer>
        </Container>
    </VRIntlProviderComponent>
    </>
};

export default GenericError;