import { IconProps } from "../../../types/icon";

const CalendarIcon28 = ({
    width = 28,
    height = 28,
    fill = "#F2F2F2"
}: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28" fill="none">
        <path
            d="M10.37 16.2458C10.1129 16.2458 9.89744 16.1626 9.72352 15.9963C9.5496 15.83 9.46264 15.6239 9.46264 15.378C9.46264 15.1322 9.5496 14.9261 9.72352 14.7598C9.89744 14.5935 10.1129 14.5103 10.37 14.5103C10.6271 14.5103 10.8427 14.5935 11.0166 14.7598C11.1905 14.9261 11.2775 15.1322 11.2775 15.378C11.2775 15.6239 11.1905 15.83 11.0166 15.9963C10.8427 16.1626 10.6271 16.2458 10.37 16.2458ZM13.9997 16.2458C13.7426 16.2458 13.5271 16.1626 13.3531 15.9963C13.1792 15.83 13.0923 15.6239 13.0923 15.378C13.0923 15.1322 13.1792 14.9261 13.3531 14.7598C13.5271 14.5935 13.7426 14.5103 13.9997 14.5103C14.2568 14.5103 14.4723 14.5935 14.6462 14.7598C14.8201 14.9261 14.9071 15.1322 14.9071 15.378C14.9071 15.6239 14.8201 15.83 14.6462 15.9963C14.4723 16.1626 14.2568 16.2458 13.9997 16.2458ZM17.6293 16.2458C17.3722 16.2458 17.1567 16.1626 16.9828 15.9963C16.8089 15.83 16.7219 15.6239 16.7219 15.378C16.7219 15.1322 16.8089 14.9261 16.9828 14.7598C17.1567 14.5935 17.3722 14.5103 17.6293 14.5103C17.8864 14.5103 18.1019 14.5935 18.2758 14.7598C18.4498 14.9261 18.5367 15.1322 18.5367 15.378C18.5367 15.6239 18.4498 15.83 18.2758 15.9963C18.1019 16.1626 17.8864 16.2458 17.6293 16.2458ZM7.64782 23.1874C7.14875 23.1874 6.72151 23.0175 6.36611 22.6776C6.01071 22.3378 5.83301 21.9292 5.83301 21.452V9.30409C5.83301 8.82685 6.01071 8.4183 6.36611 8.07845C6.72151 7.7386 7.14875 7.56867 7.64782 7.56867H8.55523V5.83325H10.37V7.56867H17.6293V5.83325H19.4441V7.56867H20.3515C20.8506 7.56867 21.2778 7.7386 21.6332 8.07845C21.9886 8.4183 22.1663 8.82685 22.1663 9.30409V21.452C22.1663 21.9292 21.9886 22.3378 21.6332 22.6776C21.2778 23.0175 20.8506 23.1874 20.3515 23.1874H7.64782ZM7.64782 21.452H20.3515V12.7749H7.64782V21.452ZM7.64782 11.0395H20.3515V9.30409H7.64782V11.0395Z"
            fill={fill}
        />
    </svg>
);

export default CalendarIcon28;
