import styled from "styled-components";
import base from "../../../../themes/base/base";
import { UserMultiSelectDropdown } from "../users-filter";

export const OptionsBarWrapperDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 0.625rem;
`;

export const ButtonText = styled.span`
    ${base.typography.largeMedium};
`;

export const StyledMultiSelectDropdown = styled(UserMultiSelectDropdown)`
    button{
        border-color: transparent;

        &:hover{
            border-color: transparent;  
        }

        &:focus{
            border-color: ${base.colors.primary.orange[500]};
        }
    }
`;

