import React from 'react';
import { SpeedControlContainer, SpeedControlInnerContainer, SpeedBlockLabel, SpeedBlock } from './styles';
import { speedBlockColors } from './utils';

const SpeedControl = () => {
  return (
    <SpeedControlContainer>
      <SpeedControlInnerContainer>
        <SpeedBlockLabel>Slower</SpeedBlockLabel>
        {speedBlockColors.map((color, index) => (
          <SpeedBlock key={index} color={color} />
        ))}
        <SpeedBlockLabel>Faster</SpeedBlockLabel>
      </SpeedControlInnerContainer>
    </SpeedControlContainer>
  );
};

export default SpeedControl;
