import { MVPFormInputs } from "../../mvp-survey/mvp-survey.types";
import { WelcomeSurveyData } from "../../survey.types";

export interface BabcockSurveyProps {
    handleOnContinue: () => void;
    clientSurveyData: WelcomeSurveyData;
    setIsMVP: React.Dispatch<React.SetStateAction<boolean>>;
    setIsClientSpecific: React.Dispatch<React.SetStateAction<boolean>>;
    mvpSurveyValues: MVPFormInputs;
};

export enum BabcockUserType {
    GLV = "GLV",
    FRS = "FRS"
};

export const babcockUserTypeRadioOptions = [
    {
        code: BabcockUserType.GLV,
        value: BabcockUserType.GLV,
        placeHolder: null
    },
    {
        code: BabcockUserType.FRS,
        value: BabcockUserType.FRS,
        placeHolder: null
    },
];
