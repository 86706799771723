import { ChartContainer, CustomColumnChart } from "./styles";
import { options, series } from './chart-config'
import { transformData } from "./utils";
import { cloneDeep } from "lodash";
import { ProgressFilters } from "../../../../enums/overview";
import { BarChartDataSet, BarRepresentation, SeriesData } from "../progress-over-time/progress-over-time.types";
import { renderToString } from "react-dom/server";

const ColumnChart = ({
    data,
    filter,
    year,
    barRepresentation,
}: {
    data: BarChartDataSet[] | undefined;
    filter: string;
    year: number | undefined;
    barRepresentation: BarRepresentation;
}) => {
    const optionsData = cloneDeep(options);
    const seriesData: { name: string; data: SeriesData[] }[] = cloneDeep(series);
    seriesData[0].data = transformData(data, filter, year, barRepresentation);

    switch(filter){
        case ProgressFilters.Last7Days:
        case ProgressFilters.Month:    
        case ProgressFilters.Year:
            optionsData.xaxis.labels.formatter = function(val:string){
                return val.substring(0,2);
            }
            break;
        default: 
            break;
    };

    const areAllAverageScoresNull = () => {
        return (data || []).every((set: BarChartDataSet) => {
            return set.score === null;
        });
    };

    //This tooltip customization allows conditional render of tooltip label "Avg Score"
    optionsData.tooltip.custom = ({ seriesIndex, dataPointIndex, w }: any) => {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        
        if (data) {
            const TooltipContent = () => {
                return (
                    <div className="custom-tooltip">
                        <span>{data.x}</span>
                        {/* This conditionally renders tooltip label "AvgScore", depending on whether all the values are number or null */}
                        {!areAllAverageScoresNull() && <span>{`Avg Score: ${Math.trunc(data.score)}`}</span>}
                        <span>{`No of Sessions: ${data.sessions}`}</span>
                        <span>{`Avg Session Time: ${data.avgSessionTime}`}</span>
                    </div>
                );
            };
            
            return renderToString(<TooltipContent />);
        };
    };

    return (
       <ChartContainer>
            <CustomColumnChart
                options={optionsData}
                series={seriesData}
                type="bar"
                height={259}
                className={filter === ProgressFilters.Month ? 'month' : ''}
            />
        </ChartContainer>
    )
};

export default ColumnChart;
