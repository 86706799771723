export interface BarChartDataSet {
    score: number | null;
    day: string;
    numberOfSessions: number;
    averageSessionTime: number;
}

export interface ProgressBarChart {
    baseLine: any | null;
    xAxisInterval: number;
    yAxisInterval: number;
    datasets: BarChartDataSet[];
    gridView: any | null;
    legend: any | null;
    label: any | null;
    value: any | null;
    chartType: string;
    code: any | null;
    description: string;
    barRepresentation: BarRepresentation;
}

export interface ProgressTopStat {
    datasets: ProgressTopStatDatasets[];
}

export interface ProgressTopStatDatasets {
    name: string,
    result: number | null,
    unit: string,
    position: number
}

export interface SeriesData {
    x: any;
    y: number | null;
    sessions: number;
    avgSessionTime: string;
    score: number | null;
}

export enum BarRepresentation {
    Score = "score",
    NumberOfSessions = "numberOfSessions",
    AverageSessionTime = "averageSessionTime",
}
