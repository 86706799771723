import styled, { css } from "styled-components";
import base from "../../themes/base/base";
import { desktopLargeMedia } from "../../themes/breakpoints";

export const SessionItemContainer = styled.a`
    display: flex;
    align-items: center;
    padding: 1.125rem 1.5rem;
    border-radius: 0.5rem;
    background: ${base.colors.neutral.grey[800]};
    cursor: pointer;
    transition: all 300ms;
    text-decoration: none;
    user-select: none;
    width: 100%;
    margin-bottom: 1rem;

    ${desktopLargeMedia} {
        width: 83%;
        align-self: flex-end;
        margin-bottom: 1.5rem;
    };

    & .chevron-container svg path {
        transition: all 300ms;
    }

    &:hover {
        background: ${base.colors.neutral.grey[600]};

        .chevron-container svg path {
            fill: #f2f2f2;
        }
    }
`;

export const SessionIconContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
`;

export const StyledImg = styled.img`
    width: 4rem;
`;

export const SessionInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    height: 100%;
    margin-left: 1rem;
`;

export const SessionTitle = styled.div`
    ${base.typography.extraLargeMedium};
    
    color: ${base.colors.neutral.grey[50]};

    .bullet-point {
        margin: 0 0.5rem;
    }
`;

export const SessionDetails = styled.div`
    ${base.typography.smallRegular};

    color: ${base.colors.neutral.grey[300]};
    display: flex;
    
    ${desktopLargeMedia} {
        ${base.typography.baseRegular};
    }
`;

export const DateTimeAndDurationContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const sharedDateTimeAndDurationStyles = css`
    gap: 0.5rem;
    display: flex;
    align-items: center;
    color: ${base.colors.neutral.grey[300]};;
`;

export const DateAndTimeDiv = styled.div`
    ${sharedDateTimeAndDurationStyles};
`;

export const DurationDiv = styled.div`
    ${sharedDateTimeAndDurationStyles};
`;

const sharedStyles = css`
    gap: 0.25rem;
    display: flex;
    align-items: center;
`;

export const DateDiv = styled.div`
    ${sharedStyles};
`;

export const TimeDiv = styled.div`
    ${sharedStyles};
`;

export const SingleUserNameAndIconDiv = styled.div`
    display: flex;
    gap: 0.25rem;
`;

export const MultipleUsersNamesAndIconDiv = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

export const IndividualUserDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

export const DetailItemTextSpan = styled.span`
    ${base.typography.baseRegular};
`;

export const BulletPoint = styled.span`
    font-size: 1.25rem;
    padding-top: 0.0625rem;
`;

export const CardArrowContainer = styled.div`
    margin-left: 1.5rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

