import styled from "styled-components";
import base from "../../../../themes/base/base";

export const Wrapper = styled.div`
  display: flex;
`;  

export const TopStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex: 0.2;
  padding-left: 1.5rem;
  border-left: 1px solid ${base.colors.neutral.grey[900]};
`;




