import styled from "styled-components";
import base from "../../../../themes/base/base";

export const Container = styled.div`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    margin-right: 1.2rem;
`;

export const GaugeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    position: relative;
`;  

export const Label = styled.div`
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.02rem;
`;

export const LegendWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 0.5rem;
    height: 2rem;
`;

export const Legend = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const LegendIcon = styled.div`
    width: 1rem;
    height: 1rem;
    background: ${props => props.color};
`;

export const LegendText = styled.span`
    ${base.typography.smallRegular};
    color: ${base.colors.neutral.grey[300]}
`;

export const EmptyStateContainer = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    height: 15.2rem;
    padding-top: 1rem;
`;

export const EmptyStateDescription = styled.span`
    ${base.typography.extraLargeMedium};
    padding: 1rem;
`;

export const EmptyStateImg = styled.img`
    width: 8rem;
    height: 8rem;
`;    