import styled from "styled-components";
import { Container } from "./../onboarding-styles";
import base from "../../../themes/base/base";

export const SuccessContainer = styled(Container)`
`;

export const Description = styled.span`
    ${base.typography.extraLargeMedium}; 
`;


export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22rem;

    button{
        margin-top: 2rem;
    }
`;