import styled from "styled-components";
import { AvatarSizes } from ".";
import base from "../../../themes/base/base";

interface ContainerProps {
    photo?: string,
    size: string,
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.primary.orange[500]};
    cursor: pointer;
    ${({ photo }) => photo && `
        background-image: url(${photo});
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    `}
    width: ${({ size }) => size && AvatarSizes[size][0]};
    height: ${({ size }) => size && AvatarSizes[size][0]};
    border-radius: 50%;
    user-select: none;
    position: relative;
    flex-shrink: 0;

    &:hover {
        box-shadow: 0 0 0 ${({ size }) => `${AvatarSizes[size][4]}px`} ${({ theme }) => theme.colors.neutral.grey[700]};
    }

    &:hover .edit {
        visibility: visible;
    }
`;

export const Initials = styled.span`
    ${base.typography.largeMedium};

    color: ${base.colors.neutral.grey[900]};
`;

export const EditContainer = styled.div`
    visibility: hidden;
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.6);
`;

export const FilePicker = styled.input.attrs({ type: 'file' })`
    display: none;
`;