import React from "react";
import { FormInput, TextInputWrapperDiv, Error } from "./text-input.styles";
import { FormattedMessage, useIntl } from "react-intl";
import { FieldValues } from "react-hook-form";
import { BottomSeparator, Label } from "../../survey.styles";
import { TextInputProps } from "./text-input.types";

const TextInput = <T extends FieldValues> ({
    registeredField,
    label,
    labelFormattedMessage,
    labelMarginBottom,
    labelHeight,
    placeholder,
    placeholderFormattedMessage,
    emptyFieldError,
    formattedRequiredMessage,
    register
}: TextInputProps<T>) => {
    const intl = useIntl();

    //API response values take precedence over hardcoded translation values
    const placeholderText = placeholder ? placeholder : intl.formatMessage({ id: placeholderFormattedMessage });
    const labelText = label ? label : <FormattedMessage id={labelFormattedMessage} />;

    //this prevents the Enter key from submitting the form
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    return (
        <TextInputWrapperDiv>
            <Label marginBottom={labelMarginBottom} height={labelHeight}>
                {labelText}
            </Label>
            <FormInput
                className={emptyFieldError ? "error" : ""}
                {...register(registeredField, {
                    required: intl.formatMessage({ id: formattedRequiredMessage })
                })}
                placeholder={placeholderText}
                onKeyDown={handleKeyDown}
            />
            {emptyFieldError && <Error>{emptyFieldError.message}</Error>}
            {!emptyFieldError && <BottomSeparator />}
        </TextInputWrapperDiv>
    );
};

export default TextInput;
