import { useQuery } from 'react-query';
import { getCombinedCustomTopStats } from '../../../../api/sessions';
import { Section } from "./styles";
import { Title } from "../../session-detail.styles";
import styled from 'styled-components';
import ContentLoader from '../../../../components/content-loader/content-loader';

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
`;

const DataPointContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const DataLabel = styled.div`
  font-size: 1.2rem;
  color: #999;
  text-align: center;
  margin-bottom: 1rem;
`;

const DataValue = styled.div`
  font-size: 2.5rem;
  text-align: center;
  color: #EE7127;
  font-weight: 300;
  padding-bottom: 0.5rem;
`;

const Divider = styled.div`
  width: 2px;
  height: calc(100% - 2rem);
  position: absolute;
  right: 0px;
  background: #121212;
  top: 1rem;
`;

function formatResult(result: string | number) {
    if (typeof result === 'string') {
        return result;
    } else {
        const fixedResult = result.toFixed(3);
        return parseFloat(fixedResult) % 1 === 0 ? parseFloat(fixedResult).toFixed(0) : fixedResult;
    }
}

export const SessionSummary = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const simulationInstanceId = queryParams.get('simulationInstanceId') || undefined;
    const scenarioInstanceId = queryParams.get('scenarioInstanceId') || undefined;
    const from = queryParams.get('from') || '';
    const to = queryParams.get('to') || '';
    const userId = queryParams.get('userId') || '';

    const { data, isFetching: isLoading } = useQuery('combinedCustomTopStats', () =>
        getCombinedCustomTopStats({
            simulationInstanceId,
            scenarioInstanceId,
            from,
            to,
            userId
        }),
        {
          suspense: false,
          refetchOnMount: true,
          useErrorBoundary: false,
          enabled: true
        }
    );

    if (isLoading) return <ContentLoader height="12rem" />

    // Assuming the data is in the format as described and is the first element in the array
    const sessionData = data?.[0]?.value || [];

    return (
        <Section.Container>
          <Section.LandingScore>
            <Title> {data?.[0].description} </Title>
            <DataContainer>
              {sessionData.map((point: { title: string; result: string; unit: string }, index: number) => (
                <DataPointContainer key={index}>
                  <div>
                    <DataLabel>{point.title}</DataLabel>
                    <DataValue>
                      {formatResult(point.result)}
                      {point.unit}
                    </DataValue>
                  </div>
                  {index !== sessionData.length - 1 && <Divider />}
                </DataPointContainer>
              ))}
            </DataContainer>
          </Section.LandingScore>
        </Section.Container>
    );
}
