import { ChangeEvent } from "react";
import {
    Controller,
    FieldValues,
} from "react-hook-form";
import {
    CustomCheckedIcon,
    CustomIcon,
    FormInput,
    Label,
    StyledRadioGroup,
    Error,
    BottomSeparator
} from "../../survey.styles";
import { FormControlLabel, Radio } from "@mui/material";
import { RadioWithInputWrapperDiv } from "./RadioWithInput.styles";
import { FormattedMessage, useIntl } from "react-intl";
import { RadioWithInputProps } from "./RadioWithInput.types";

const CustomRadio = <Radio checkedIcon={<CustomCheckedIcon />} icon={<CustomIcon className="radio-icon" />} />;

export function RadioControllerInput<T extends FieldValues>({
    register,
    control,
    errors,
    registeredField,
    label,
    labelFormattedMessage,
    labelMarginBottom,
    labelHeight,
    options,
    emptyFieldError, //the global question CODE -> if no radio is selected
    requiredRule,
    /* the following props are only needed if there's an Input associated */
    NAValue, //value that serves as error, if not filled
    inputOption,
    inputPlaceholder,
    emptyInputError, //the particular question (with input) CODE -> if input is empty
    clearErrors,
    /* the following props are only needed for a simple radio without form verification & registration */
    simpleRadioValue,
    setSimpleRadioValue,
    clearErrorsTriggerValue, // value that triggers clearing errors
    userTypeError,
    setUserTypeError
}: RadioWithInputProps<T>) {
    const intl = useIntl();

    //API response values take precedence over hardcoded translation values
    const labelText = label ? label : <FormattedMessage id={labelFormattedMessage} />

    const formOptions = options.map((option) => {
        return (
            <FormControlLabel
                key={option.code}
                value={option.code}
                control={CustomRadio}
                label={option.value}
            />    
        )
    });

    const handleChangeOptionForFormRegistration =
        (onChange: (event: ChangeEvent<HTMLInputElement>) => void) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            onChange(event);

            if (event.target.value !== NAValue) {
                clearErrors && clearErrors(registeredField);
                clearErrors && clearErrors(inputOption);
            };
    };

    const handleChangeOptionForSimpleRadio = (event: ChangeEvent<HTMLInputElement>) => {
        const radioChosenValue = event.target.value;
        const previousValue = simpleRadioValue;
        setSimpleRadioValue && setSimpleRadioValue(radioChosenValue);
        setUserTypeError && setUserTypeError(false);
        
        if (previousValue === clearErrorsTriggerValue && radioChosenValue !== clearErrorsTriggerValue) {
            clearErrors && clearErrors();
        };
    };

    return (
        <RadioWithInputWrapperDiv>
            <Label marginBottom={labelMarginBottom} height={labelHeight}>
                {labelText}
            </Label>
            {/* this condition determines if the radio will be used as a registered form field
            or a simple radio without form registration & validation */}
            {registeredField ? (
                <>
                    <Controller
                        control={control}
                        name={registeredField}
                        rules={{ required: requiredRule }}
                        render = {({ field: { onChange, onBlur, value } }) => {
                            return (
                                <StyledRadioGroup
                                    className={errors?.[registeredField] ? "error": ""}
                                    value={value}
                                    onChange={handleChangeOptionForFormRegistration(onChange)}
                                    onBlur={onBlur}
                                >
                                    {formOptions}
                                    {inputOption &&
                                        <FormInput
                                            className={(value === NAValue && errors?.[inputOption]) ? "error": ""}
                                            {...register?.(inputOption, {
                                                validate: value === NAValue ? (inputValue) => inputValue ? true : intl.formatMessage({ id: "survey:required" }) : undefined
                                            })}
                                            placeholder={inputPlaceholder}
                                        />
                                    }
                                </StyledRadioGroup>
                            )
                        }}
                    />
                    {emptyFieldError && <Error>{emptyFieldError.message}</Error>}
                    {emptyInputError && <Error>{emptyInputError.message}</Error>} 
                    {(!emptyFieldError && !emptyInputError) && <BottomSeparator />}
                </>
            ) : (
                <>
                    <StyledRadioGroup
                        value={simpleRadioValue}
                        onChange={handleChangeOptionForSimpleRadio}
                        hasError={userTypeError}
                    >
                        {formOptions}
                    </StyledRadioGroup>
                    {userTypeError && <Error><FormattedMessage id={"survey:required"} /></Error>}
                    {!userTypeError && <BottomSeparator />}
                </>
            )}
        </RadioWithInputWrapperDiv>
    )
}