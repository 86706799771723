import styled from "styled-components";
import base from "../../themes/base/base";
import { ContentLoaderProps } from "./content-loader.types";

export const ContentLoaderWrapper = styled.div<ContentLoaderProps>`
    display: flex;
    height: ${({ height }) => (height ? height : "10rem")};
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${base.colors.neutral.grey[800]};
    border-radius: 0.5rem;
`;
