import styled from "styled-components";
import base from "../../../../themes/base/base";
import UIButton from "../../../../components/ui/button";

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const Toggle = styled(UIButton)`
  ${base.typography.extraSmallRegular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  max-width: 28.75rem;
  border-radius: 2px;
  padding: 0.1875rem 0.375rem;
  border: 1px solid ${base.colors.neutral.grey[300]};

  &:hover {
    background-color: ${base.colors.neutral.grey[600]};
    border: 1px solid ${base.colors.neutral.grey[300]};
  }   

  &:focus {
    box-shadow: none;
    border: 1px solid ${base.colors.primary.orange[500]};
  }
`;

export const PopUp = styled.div<{ width: string | number }>`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  width: ${({ width }) => width};
  border: 0;
  border-radius: 2px;
  background-color: ${base.colors.neutral.grey[800]};
  z-index: 1;
`;

export const ContextFilterPopUp = styled(PopUp)`
  position: absolute;
  right: 0;
  border: 1px solid #999;
`;

export const PresetsList = styled.ul``;

export const Preset = styled.li`
  list-style: none;
  padding: 0.5rem 1.25rem 0.5rem 0.75rem;
  display: flex;
  align-items: center;
`;

export const PresetsContainer = styled.div`
  width: 9rem;
`;

export const Link = styled.a<{ selected: boolean }>`
  ${base.typography.extraSmallRegular};
  
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  border-radius: 0.2rem;
  color: ${({ selected }) => (selected ? '#EE7127' : 'inherit')};
  
  &:hover {
    color: #EE7127;
  }
`;

export const FilterContainer = styled.div`
    position: relative;
`;
