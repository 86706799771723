import styled, { css } from "styled-components";
import base from "../../themes/base/base";

export const CalendarContainer = styled.div`
    padding: 1rem;

    @media (max-width: 1023px) {
        width: 100%;
        margin: 0;
        padding: 1rem;
    }

    //navigation container
    .react-calendar__navigation {
        display: flex;
        gap: 0.25rem;
        margin-bottom: 1.25rem;
        height: unset;
        height: 1.5rem;
    }

    //navigation header title
    .react-calendar__navigation__label__labelText {
        font-family: "Roboto";
        ${base.typography.baseMedium};
    }

    //navigation icons -> chevrons
    .react-calendar__navigation button {
        padding: 0;
    }

    //weekdays container
    .react-calendar__month-view__weekdays {
        height: 2.5rem;

        .react-calendar__month-view__weekdays__weekday {
            font-family: "Roboto";
            ${base.typography.baseRegular};
        }
    }

    //border radius of selected date range (in darker orange)
    .react-calendar__tile--rangeStart {
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;
    }

    //border radius of selected date range (in darker orange)
    .react-calendar__tile--rangeEnd {
        border-top-right-radius: 1.25rem;
        border-bottom-right-radius: 1.25rem;
    }

    //all days (selected days override this)
    .react-calendar__month-view__days {
        display: flex;
        row-gap: 0.25rem;
    }

    //month days container
    .react-calendar__tile {
        font-family: "Roboto";
        ${base.typography.smallRegular};

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2.5rem !important;
        height: 2.5rem !important;

        & abbr {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
        }

        //today
        &--now {
            border-radius: 2rem;
            border: 1px solid ${base.colors.primary.orange[500]};
            background-color: transparent;
            color: unset;

            &.react-calendar__tile--hoverEnd {
                border-top: 1px dashed ${base.colors.neutral.grey[300]};
                border-bottom: 1px dashed ${base.colors.neutral.grey[300]};
                border-right: 1px dashed ${base.colors.neutral.grey[300]};
                border-left: 0;
                border-radius: 0;
                border-top-right-radius: 2rem;
                border-bottom-right-radius: 2rem;
                background-color: transparent;
            }

            &.react-calendar__tile--hoverStart {
                border-top: 1px dashed ${base.colors.neutral.grey[300]};
                border-bottom: 1px dashed ${base.colors.neutral.grey[300]};
                border-left: 1px dashed ${base.colors.neutral.grey[300]};
                border-right: 0;
                border-radius: 0;
                border-top-left-radius: 2rem;
                border-bottom-left-radius: 2rem;
            }

            &:after {
                background-color: transparent !important;
            }
        }

        //selected date range
        &--active {
            font-family: "Roboto";
            ${base.typography.smallMedium};

            color: ${base.colors.neutral.grey[50]};
            background-color: ${base.colors.primary.orange[900]} !important;

            &.react-calendar__tile--rangeBothEnds {
                background-color: transparent !important;
            }

            //so the whole button doesn't have the border, only the abbr
            &.react-calendar__tile--now {
                border: none;
            }
        }

        //1st & last days selected
        &--rangeStart,
        &--rangeEnd {
            color: ${base.colors.neutral.grey[900]};

            > abbr {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                background-color: ${base.colors.primary.orange[500]};
                border-radius: 2rem;
            }
        }

        //last day selected
        &--rangeEnd {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            //black border in last day selected
            > abbr {
                border: 1px solid ${base.colors.primary.orange[500]} !important;
                box-shadow: inset 0 0 0 1px ${base.colors.neutral.grey[900]};
            }
        }

        //hover state for the day button
        &:hover {
            background-color: transparent;

            > abbr {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${base.colors.primary.orange[700]};
                border-radius: 2rem;
            }
        }

        //button hover styles
        &--hover {
            border-top: 1px dashed ${base.colors.neutral.grey[300]} !important;
            border-bottom: 1px dashed ${base.colors.neutral.grey[300]} !important;
            background-color: transparent;
        }

        //button hover styles - for first day selected
        &--hoverStart {
            border-top: 1px dashed ${base.colors.neutral.grey[300]} !important;
            border-bottom: 1px dashed ${base.colors.neutral.grey[300]} !important;
            border-left: 1px dashed ${base.colors.neutral.grey[300]} !important;
            border-radius: 0;
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
        }

        //button hover styles - for last day selected
        &--hoverEnd {
            border-top: 1px dashed ${base.colors.neutral.grey[300]} !important;
            border-bottom: 1px dashed ${base.colors.neutral.grey[300]} !important;
            border-right: 1px dashed ${base.colors.neutral.grey[300]} !important;
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
            background-color: transparent;
        }

        //button hover styles - for both 1st and last day selected
        &--hoverBothEnds {
            &:hover {
                border: 0;
            }
        }
    }

    @media (max-width: 1023px) {
        border-left: none;
        padding: 2.3rem 3.21875rem 1rem;
        border-bottom: 1px solid ${base.colors.neutral.grey[300]};
    }
`;

export const Columns = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1023px) {
        flex-direction: column-reverse;
    }
`;

export const PresetsContainer = styled.div`
    width: 8.25rem;
    border-right: 1px solid ${base.colors.neutral.grey[300]};

    @media (max-width: 1023px) {
        display: none;
    }
`;

export const PresetsList = styled.ul`
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
        min-width: 100%;
        display: flex;
    }
`;

export const Preset = styled.li`
    padding: 0.5rem 1.25rem 0.5rem 0.75rem;
    list-style: none;
    display: flex;
    align-items: center;

    &:first-child {
        margin: 0;
    }

    &:hover {
        background-color: ${base.colors.neutral.grey[600]};
    }

    @media (max-width: 1023px) {
        text-wrap: nowrap;
        -moz-text-wrap: nowrap;
        -webkit-text-wrap: nowrap;
    }
`;

export const Link = styled.a<{ selected: boolean }>`
    ${({ selected }) =>
        selected
            ? css`
                  ${base.typography.smallMedium};
              `
            : css`
                  ${base.typography.smallRegular};
              `};

    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    color: ${({ selected }) => (selected ? `${base.colors.primary.orange[500]}` : "inherit")};

    &:hover {
        color: ${base.colors.primary.orange[500]};
    }
`;

export const SelectedPresetMessage = styled.span`
    ${base.typography.smallRegular};
`;

export const DateRangeLabel = styled.div`
    ${base.typography.smallRegular};

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
`;
