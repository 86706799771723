import { FormattedMessage } from "react-intl"
import { EmptyStateContainer, EmptyStateDescription, EmptyStateImg } from "./gauge-styles"
import { EmptyChartsStateIcon } from "../../../../components/icons";

const EmptyState = () => {
    return(
        <EmptyStateContainer>
            <EmptyChartsStateIcon/>    
            <EmptyStateDescription>
                <FormattedMessage id={"session-detail:gauge-empty-state-description"} />
            </EmptyStateDescription>
         </EmptyStateContainer>
    )
};

export default EmptyState;