import styled from "styled-components";
import base from "../../themes/base/base";
import { SecondaryButton } from "../shared/shared.styles";

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - var(--heat-header-height) - var(--sessions-page-header-height));
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
    background-color: ${base.colors.neutral.grey[800]};
    align-items: center;
    border-radius: 0.5rem;
    width: 45.5rem;
    height: 20.8125rem;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};
`;

export const Description = styled.span`
    ${base.typography.medium};
`;

export const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

export const FileTypeToggle = styled(SecondaryButton)`
    ${base.typography.smallRegular};
    
    background: ${base.colors.neutral.grey[900]};
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding: 0.4375rem 0.75rem;
    border-radius: 0.125rem;
    border: 1px solid ${base.colors.neutral.grey[900]};

    &:hover {
        border: 1px solid ${base.colors.neutral.grey[800]};
    }

    &:focus {
        border: 1px solid ${base.colors.primary.orange[500]};
        z-index: 2;
    }

    &:disabled {
        color: ${base.colors.neutral.grey[500]};
        background-color: ${base.colors.neutral.grey[900]};
        border: 1px solid ${base.colors.neutral.grey[900]};

        & svg path {
            fill: ${base.colors.neutral.grey[500]};
        }
    }

    svg:nth-of-type(2) {
        margin-left: auto;
    }
`;

export const LoaderContainer = styled.div`
    display: flex;
    padding: 0.09375rem 0;
`;

export const Text = styled.span` 
`;

