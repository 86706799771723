import styled from "styled-components";
import base from "../../../../../themes/base/base";

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ControlsInnerContainer = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TimeDisplay = styled.span<{ isDisabled: boolean }>`
  ${base.typography.smallMedium};
  
  align-self: flex-end;
  color: ${({ isDisabled }) => (isDisabled ? '#383838' : '#999999')};
`;