export interface EmptyStateProps {
    title: string;
    description: string;
    emptyStateType: EmptyStateType;
    svgWidth?: number;
    svgHeight?: number;
}

export enum EmptyStateType {
    Sessions = "sessions",
    Charts = "charts",
    Error = "error"
}
