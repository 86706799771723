import { completeRegistration } from "../../../api/user";
import { SurveySubmitProps } from "./survey.types";
import { SurveyQuestionType } from "../../../enums/survey";

export const radioWithInputLastQuestionCode = (question: any) => {
    if (!question.options || question.options.length === 0) {
        return null;
    }
    return question.options[question.options.length - 1].code;
};

export const radioWithInputLastQuestionPlaceholder = (question: any) => {
    if (!question.options || question.options.length === 0) {
        return null;
    }
    return question.options[question.options.length - 1].placeHolder;
};

//the purpose of this function is to remove empty data fields
export const cleanRadioInputEmptyData = (formData: any) => {
    let cleanedData: any = {};

    Object.entries(formData).forEach(([key, value]) => {
        if (value !== "") {
            cleanedData[key] = value;
        }
    });

    return cleanedData;
};

export const handleSurveySubmit = async ({
    getValues,
    mappedMvpSurveyValues,
    clientSurveyData,
    questions,
    setIsLoading,
    handleOnContinue,
    isValid
}: SurveySubmitProps) => {
    const rawFormData = getValues();

    const cleanedFormData = cleanRadioInputEmptyData(rawFormData);

    const surveyAnswers = questions.map((question: any) => {

        let answerCode = cleanedFormData[question.code];

        let answerRawValue = answerCode;

        if (question.options) {
            // Attempts to find the question whose code matches the answerCode
            const foundOption = question.options.find(
                (option: any) => {
                    if (question.type === SurveyQuestionType.NumericSlider) {
                            return option.value === cleanedFormData[question.code].toString();
                    } else {
                        return option.code === answerCode;
                    };
            });

            if (foundOption) {
                answerCode = foundOption.code;
                answerRawValue = foundOption.value;
            };

            // Check if the selected Radio option requires mandatory input
            if (answerCode === foundOption?.code && foundOption?.placeHolder) {
                const inputCode = radioWithInputLastQuestionCode(question);
                const inputAnswer = cleanedFormData[inputCode];
                answerRawValue = inputAnswer ? inputAnswer : answerRawValue;
            };
        };

        return {
            surveyCode: clientSurveyData.code,
            surveyName: clientSurveyData.name,
            surveyType: clientSurveyData.type,
            sectionCode: clientSurveyData.sections?.[0].code,
            sectionTitle: clientSurveyData.sections?.[0].title,
            questionCode: question.code,
            questionTitle: question.title,
            answerCode: answerCode,
            answerRawValue: answerRawValue
        };
    });

    const combinedForBEPayload = {
        ...mappedMvpSurveyValues,
        surveyAnswers
    };

    if (isValid) {
        setIsLoading(true);
        await completeRegistration(combinedForBEPayload);
        handleOnContinue();
    };
};
