import { useCallback, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage, useIntl } from "react-intl";
import { getAllTrainees } from "../../api/trainee";
import {
  UsersIcon24,
  ChevronDownSquareCornersIcon24,
  UsersIcon, UsersIcon32,
} from "../icons";
import { FiltersList } from "../../enums/dashboard";
import { Trainee, TraineeResponse } from "../../types/trainee";
import {
  AllTrainees,
  AvatarContainer,
  Container,
  Item,
  List,
  ListContainer,
  LoaderContainer,
  Name,
  Toggle,
  TraineesPopUp,
  AllTraineesContainer,
  AvatarIconContainer,
  TextSpan,
  SearchBarDiv,
  StyledText,
  StyledAvatar,
  StyledLoader,
  IconAndTitleWrapper,
} from "./filters.styles";
import useMediaQuery from "../../utils/hooks/use-media-query";
import SearchBar from "../ui/search-bar/search-bar";
import base from "../../themes/base/base";

const mapTraineeResponse = ({ userId, displayName, createDate, email, lastInvitationSent, status }: TraineeResponse): Trainee => ({
  id: userId,
  email,
  name: displayName,
  dateAdded: createDate,
  lastInvitationSent,
  status,
});

const fetchAllTrainees = async (search: string) => await getAllTrainees(search);

type TraineesFilterProps = {
  isOpen: boolean;
  handleOnClick: (name: string | null) => void;
  onUserSelected: (userId: string | null) => void; // Allow null here
  initialUserId: string | null;
  fullWidth?: boolean;
  backgroundColor?: string;
};

const TraineesFilter = ({
  isOpen,
  backgroundColor,
  handleOnClick,
  onUserSelected: onUserSelectedProp,
  initialUserId,
  fullWidth,
  ...props
}: TraineesFilterProps) => {
  const history = useHistory();
  const [userId, setUserId] = useState<string | null>(initialUserId);
  const [allTraineesOriginalOrder, setAllTraineesOriginalOrder] = useState<Trainee[]>([]);
  const [allTrainees, setAllTrainees] = useState<Trainee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState("");
  const [searchLength, setSearchLength] = useState(0);
  const isMobileAndTablet = useMediaQuery("(max-width: 1023px)");

  useEffect(() => {
    setUserId(initialUserId);
  }, [initialUserId]);

  useEffect(() => {
    setIsLoading(true);
    fetchAllTrainees(search)
      .then((items) => {
        const mappedItems = items.map((res: TraineeResponse) => mapTraineeResponse(res)) || [];
  
        // if a trainee is selected, move it to the top of the list
        if (userId) {
          const selectedIndex = mappedItems.findIndex((item: any) => {
            return item.id === userId
          });
  
          if (selectedIndex > -1) {
            const [selectedTrainee] = mappedItems.splice(selectedIndex, 1);
            mappedItems.unshift(selectedTrainee);
          }
        };
  
        setAllTraineesOriginalOrder(mappedItems);
        setAllTrainees(mappedItems);
        setIsLoading(false);
      })
      .catch(() => {});
  }, [search, userId]);
  
  const handleSearchChange = useCallback(
    (e: any) => {
      setAllTrainees([]);
      setSearch(e.target.value);
      setSearchLength(e.target.value.length);
    },
    [search]
  );

  const handleToggleList = useCallback(
    (e: any) => {
      handleOnClick(isOpen ? null : FiltersList.TraineesList);

      //clean the search in input, if there is one
      if (searchLength > 1) {
        setSearch("");
        setSearchLength(0);
      };
    },
    [isOpen, searchLength, search]
  );

  const handleUserSelected = useCallback((userId: string | null) => {
      if (userId) {
        //move the selected trainee to the top of the list
        setAllTrainees((prevTrainees) => {
          const updatedTrainees = [...prevTrainees];
          const selectedIndex = updatedTrainees.findIndex((item) => {
            return item.id === userId
          });
  
          if (selectedIndex > -1) {
            const [selectedTrainee] = updatedTrainees.splice(selectedIndex, 1);
            updatedTrainees.unshift(selectedTrainee);
          };
  
          return updatedTrainees;
        });
  
        setUserId(userId);
        onUserSelectedProp(userId);
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('traineeId', userId);
        history.push({ search: queryParams.toString() });
      } else {
        //reset the list to the original order if "All Trainees" is selected
        setAllTrainees(allTraineesOriginalOrder);
        setUserId(null);
        onUserSelectedProp(null);
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('traineeId');
        history.push({ search: queryParams.toString() });
      }
    },
    [allTraineesOriginalOrder, onUserSelectedProp, history]
  );

  const selectedTrainee = allTrainees.find(({ id }) => {
    return (id === userId) || null;
  });

  return (
    <Container backgroundColor={backgroundColor} {...props}>
      {isMobileAndTablet &&
        <Toggle
            isOpen={isOpen}
            backgroundColor={backgroundColor}
            fullWidth={fullWidth}
            onClick={handleToggleList}
            data-test-id="trainee-dd"
        >
          <UsersIcon32 />
        </Toggle>
      }
      {!isMobileAndTablet &&
        <Toggle
            isOpen={isOpen}
            backgroundColor={backgroundColor}
            fullWidth={fullWidth}
            onClick={handleToggleList}
            data-test-id="trainee-dd"
        >
          {selectedTrainee ? (
            <>
              <IconAndTitleWrapper>
                <UsersIcon24 />
                <Name>
                    <TextSpan>{selectedTrainee.name}</TextSpan>
                </Name>
              </IconAndTitleWrapper>
              <ChevronDownSquareCornersIcon24 />
            </>
          ) : (
            <>
              <IconAndTitleWrapper>
                <UsersIcon24 />
                  <Name>
                    <FormattedMessage id="filters:all-trainees" />
                  </Name>
              </IconAndTitleWrapper>
              <ChevronDownSquareCornersIcon24 />
            </>
          )}
        </Toggle>
      }
      {isOpen && (
        <ListPopUp
          search={search}
          onSearchChange={handleSearchChange}
          selected={userId} // Use local userId state
          isLoading={isLoading}
          allTrainees={allTrainees}
          onClose={() => handleOnClick(null)}
          onClick={() => handleOnClick(null)}
          onUserSelected={handleUserSelected} // Pass the new handler here
        />
      )}
    </Container>
  );
};

const ListPopUp = ({
  search,
  onSearchChange,
  selected,
  isLoading,
  allTrainees = [],
  onClose,
  onClick,
  onUserSelected,
}: {
  search: string;
  onSearchChange: (e: any) => void;
  selected: string | null; // Allow null here
  isLoading: boolean;
  allTrainees: Trainee[];
  onClose: () => void;
  onClick: (e: any) => void;
  onUserSelected: (userId: string | null) => void; // Allow null here
}) => {
  const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });
  const intl = useIntl();

  const isAllSelected = selected === null;

  return (
    <TraineesPopUp ref={clickOutsideRef} width={"19.5rem"}>
      <SearchBarDiv>
        <SearchBar
          width={"100%"}
          debounceTimeout={400}
          placeholder={intl.formatMessage({ id: "filters:search-for-trainee" })}
          value={search}
          onChange={onSearchChange}
          backgroundColor={base.colors.neutral.grey[900]}
        />
      </SearchBarDiv>

      <ListContainer>
        <List>
          <AllTrainees
            selected={isAllSelected}
            onClick={(e) => {
              onClick(e);
              onUserSelected(null);
            }}
            data-test-id="all-trainees"
          >
            <AllTraineesContainer>
              <AvatarContainer>
                <AvatarIconContainer>
                  <UsersIcon width="16" height="16" />
                </AvatarIconContainer>
              </AvatarContainer>
              <FormattedMessage id="filters:all-trainees" />
            </AllTraineesContainer>
          </AllTrainees>

          {allTrainees.map(({ id, name }, index) => {
            return (
              <Item
                key={id}
                selected={selected === id}
                onClick={(e) => {
                  onClick(e);
                  onUserSelected(id);
                }}
                data-test-id={`trainee-${index+1}`}
                >
                  <AvatarContainer>
                    <StyledAvatar name={name} size="small" editable={false} />
                  </AvatarContainer>
                  <Name className="name">
                    <StyledText selected={selected === id}>{name}</StyledText>
                  </Name>
              </Item>
            );
          })}
          
          {isLoading && (
            <LoaderContainer>
              <StyledLoader />
            </LoaderContainer>
          )}
        </List>
      </ListContainer>
    </TraineesPopUp>
  );
};

export default TraineesFilter;
