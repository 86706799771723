import React, { useMemo, useCallback } from "react";
import {
    BottomWrapper,
    SessionsWrapperDiv,
    HeaderWrapper,
    ViewAllButton,
    ButtonContentWrapper,
    EmptySessionCard
} from "./sessions.styles";
import {
    FormattedSessions,
    OverviewSession,
    Scenario,
    SessionsProps
} from "./sessions.types";
import Text from "../../../../components/ui/text";
import SessionCard from "../session-card";
import { ChevronRightSquareCornersIcon24 } from "../../../../components/icons";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSessionsList } from "./useSessionsList";
import EmptyState from "../../../../components/empty-state/empty-state";
import { EmptyStateType } from "../../../../components/empty-state/empty-state.types";
import ContentLoader from "../../../../components/content-loader/content-loader";

const Sessions: React.FC<SessionsProps> = ({ userId }) => {
    const history = useHistory();
    const { sessionList, isFetching, sessionsListUrl, isError } =
        useSessionsList(userId);
        
    const mainSessionsScenarios = useMemo(() => {
        const allScenariosArray: FormattedSessions[] = [];

        sessionList?.forEach((session: OverviewSession) => {
            session.sessions.forEach((scenario: Scenario) => {
                let userCount = '';
                if((session?.users || []).length > 1){
                    let count = session.users.toString().split(',').length - 1;
                    userCount += '+' + count.toString();
                } 
                allScenariosArray.push({
                    session: {
                        id: session.id,
                        simulationName: session.simulationName,
                        traineeName: session.name + ' ' + userCount,
                        logoUrl: session.logoUrl,
                        userId: session.userId,
                        users:session.users
                    },
                    scenario
                });
            });
        });

        return allScenariosArray.slice(0, 3);
    }, [sessionList]);

    const noSessionsData = useMemo(
        () => mainSessionsScenarios.length === 0 && !isError,
        [mainSessionsScenarios.length, isError]
    );
    const disabledState = isFetching || noSessionsData;

    const handleViewAllClick = useCallback(() => {
        history.push(sessionsListUrl);
    }, [sessionsListUrl, history]);

    const renderScenarioCards = useCallback(
        (scenarios: FormattedSessions[]) => {
            if (scenarios.length > 0 && scenarios.length < 3) {
                return (
                    <>
                        {scenarios.map((scenario, index) => (
                            <SessionCard
                                key={scenario.scenario.scenarioInstanceId}
                                sessionInfo={scenario.session}
                                scenarioInfo={scenario.scenario}
                                index={index}
                            />
                        ))}
                        <EmptySessionCard />
                        {scenarios.length === 1 && <EmptySessionCard />}
                    </>
                );
            }

            if (scenarios.length >= 3) {
                return scenarios.map((scenario, index) => (
                    <SessionCard
                        key={scenario.scenario.scenarioInstanceId}
                        sessionInfo={scenario.session}
                        scenarioInfo={scenario.scenario}
                        index={index}
                    />
                ));
            }
        },
        []
    );

    const renderSessions = () => {
        if (isFetching) {
            return (
                <ContentLoader height="442px" />
            );
        }

        if (isError) {
            return (
                <EmptyState
                    title="overview:sessions:error-state:title"
                    description="overview:sessions:error-state:description"
                    emptyStateType={EmptyStateType.Error}
                />
            );
        }

        if (noSessionsData) {
            return (
                <EmptyState
                    title="overview:sessions:empty-state:title"
                    description="overview:sessions:empty-state:description"
                    emptyStateType={EmptyStateType.Sessions}
                />
            );
        }

        return renderScenarioCards(mainSessionsScenarios);
    };

    return (
        <SessionsWrapperDiv>
            <HeaderWrapper>
                <Text variant="extraLargeMedium">
                    <FormattedMessage id="overview:sessions:title" />
                </Text>
                <Text variant="extraSmallRegular" color="neutral.grey.300">
                    <FormattedMessage id="overview:sessions:description" />
                </Text>
            </HeaderWrapper>
            {renderSessions()}
            <BottomWrapper>
                <ViewAllButton
                    disabled={disabledState}
                    onClick={handleViewAllClick}
                    data-test-id="view-all"
                >
                    <ButtonContentWrapper>
                        <FormattedMessage id="overview:sessions:view-all-button" />
                        <ChevronRightSquareCornersIcon24
                            fill={disabledState ? "#666666" : "#ee7127"}
                        />
                    </ButtonContentWrapper>
                </ViewAllButton>
            </BottomWrapper>
        </SessionsWrapperDiv>
    );
};

export default Sessions;
