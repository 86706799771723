import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";
import base from "../../../themes/base/base";

export const TooltipContent = styled.div`
    ${base.typography.smallMedium};
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
`;

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 218,
        fontSize: `1.5625rem`, //size of the arrow
        background: "#383838",
        padding: "0.5rem",
        borderRadius: "0.25rem",
    },

    [`& .${tooltipClasses.arrow}`]: {
        color: "#383838"
    }
});

export const StyledImg = styled.img`
    vertical-align: middle;
`;

export const StyledUl = styled.ul`
    display: block;
    padding-inline-start: 1.5625rem;
`;
