import { IconProps } from "../../../types/icon";

const ChevronDownSquareCornersIcon24 = ({
    fill = "#F2F2F2",
    width = 24,
    height = 24
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M7.07273 9L6 10.0677L12 16L18 10.0617L16.9273 9L12 13.8766L7.07273 9Z"
            fill={fill}
        />
    </svg>
);

export default ChevronDownSquareCornersIcon24;
