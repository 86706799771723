import base from "../../../../themes/base/base";

export const series = [{
    name: "progress",
    data: []
}];

export const options: any = {
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false 
      },
      animations: {
        enabled: false,
      },
    },

    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '50%',
        }
    },
  
    dataLabels: {
      enabled: false
    },
  
    legend: {
      show: false // Hide the legend
    },
  
    grid: {
      strokeDashArray: 8,
      borderColor: "#53555A",
      padding: {
        top: -29.5 //to remove the space above the first grid line
      }
    },

    xaxis: {
      crosshairs: {
        show: false // <--- HERE
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      tooltip: {
        enabled: false
      },
      labels:{
        style: {
            colors: base.colors.neutral.grey[50],
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: "Roboto"
            }
        } 
    },
  
    yaxis: {
        labels: {
            show: false
        } 
    },

    tooltip: {
        enabled: true,
        /* tooltip customization moved to column-chart component, to allow conditional render of labels */
    },

    colors: [base.colors.primary.orange[500]],

  };
