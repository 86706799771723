import moment from "moment";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import useQueryParams from "../../utils/use-query-params";
import {
  TimeDiv,
  Main,
  NameAndIconDiv,
  UserAndSessionInfoWrapper,
  TraineeNameSpan,
  DateDiv,
  BulletPoint,
  DurationDiv,
} from "./session-detail.styles";
import { LandingScore } from "./components/landing-score";
import { OverallScore } from "./components/overall-score";
import { EyeTracking } from "./components/eye-tracking";
import { SessionSummary } from "./components/session-summary";
import { ResponseTime } from './components/response-time'
import CustomTopStats from "./components/custom-top-stats";
import TimelineChartFiring from "./components/timeline-chart-with-firing";
import MapTimeline from './components/map-timeline/map-timeline';
import { FireMissions } from './components/fire-missions';
import { getTimeDifference } from "./components/timeline-chart-with-firing/utils";
import LayoutGrid from '../../components/layout-grid/layout-grid';
import Modal from '../../components/ui/modal';
import TimelineChartDriving from "./components/timeline-chart-driving";
import { useQuery } from "react-query";
import { getCustomerSettings } from "../../api/settings";
import { CalendarIcon24, ClockIcon24, UsersIcon24 } from "../../components/icons";
import { SemiCircularGauge } from "./components/semi-circular-gauge";
import { PerformanceScore } from "./components/performance-score/performance-score";
import TimelineChartFlight from "./components/timeline-chart-flight/timeline-chart-flight";
import CockpitTimeline from "./components/cockpit-timeline";
import { fetchCombinedChartsData } from "./api/fetchCombinedChartsData";
import { fetchCustomChartsData } from "./api/fetchCustomChartsData";

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

const SessionDetail = () => {
  const intl = useIntl();
  const params: any = useQueryParams();

  const sessionPeriodFrom = params.get("from");
  const sessionPeriodTo = params.get("to");
  const name = params.get("name");
  const simulationName = params.get("simulationName");
  const traineeName = params.get("traineeName");
  const logoUrl = params.get("logoUrl");

  //Passing data to components to avoid multiple calls - except for the ones with polling
  const { combinedChartsData, isFetching: isFetchingCombinedCharts } = fetchCombinedChartsData();
  const { customChartsData, isFetching: isFetchingCustomCharts } = fetchCustomChartsData();

  const sessionDuration = getTimeDifference(new Date(sessionPeriodFrom),new Date(sessionPeriodTo));
  const { state }: {state: Record<string, string>} = useLocation();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  function getUsers(): string[] {
    const cacheUsers = localStorage.getItem("users");
    if (cacheUsers !== null) {
        const parseUsers = JSON.parse(cacheUsers);
        const usersString = JSON.stringify(parseUsers);

        const splitString = usersString.split(",");

        for (let y = 0; y < splitString.length; y++) {
            splitString[y] = splitString[y].replace(/['"]+/g, "");
            splitString[y] = splitString[y].charAt(0).toUpperCase() + splitString[y].slice(1)
                .replace('=>', ' - ');
        }

        return splitString;
    }
    return []; // Return an empty array if cacheUsers is null
  };

  const users = getUsers();

  const getSubtitleSessionLabel = useMemo(() => {
    const sessionDate = moment(sessionPeriodFrom || sessionPeriodTo).format("DD/MM/YYYY");
    const fromTime = moment(sessionPeriodFrom).format("HH:mm");     

    if (localStorage.getItem("users") === null) {
      return (
        <UserAndSessionInfoWrapper>
          <NameAndIconDiv>
            <UsersIcon24 fill="#999999" />
            <TraineeNameSpan>{state?.traineeName || traineeName}</TraineeNameSpan>
          </NameAndIconDiv>

          <DateDiv>
            <CalendarIcon24 />
            {sessionDate}
          </DateDiv>

          <TimeDiv>
            <ClockIcon24 />
            {fromTime}
          </TimeDiv>

          <BulletPoint>{`\u2022`}</BulletPoint>

          <DurationDiv>{sessionDuration}</DurationDiv>
        </UserAndSessionInfoWrapper>
      );
    } else {
      return (
        <UserAndSessionInfoWrapper>
          {users?.map((user, index) => {
            return (
              <NameAndIconDiv key={`${index}-${user}`}>
                <UsersIcon24 fill="#999999" />
                <TraineeNameSpan>{user}</TraineeNameSpan>
              </NameAndIconDiv>
            );
          })}
        
        <DateDiv>
            <CalendarIcon24 />
            {sessionDate}
          </DateDiv>

          <TimeDiv>
            <ClockIcon24 />
            {fromTime}
          </TimeDiv>

          <BulletPoint>{`\u2022`}</BulletPoint>

          <DurationDiv>{sessionDuration}</DurationDiv>
        </UserAndSessionInfoWrapper>
      );
    }
  }, [sessionPeriodFrom, sessionPeriodTo, state?.traineeName, traineeName, sessionDuration]);

  let title = useMemo(() => {
    const sessionTitle = name || intl.formatMessage({ id: "session-detail:title" });
    return `${state?.simulationName || simulationName || ''} \u2022 ${sessionTitle}`;
  }, [state?.simulationName, simulationName, name, intl]);

  const options = {
    suspense: false,
    refetchOnMount: false,
    useErrorBoundary: true,
  };

  const { isLoading, data: d } = useQuery([getCustomerSettings], getCustomerSettings, options); 

  if(d?.customerName == "ra-mlrs"){
      title = `${'TCT - MLRS'} \u2022 ${'Scenario 01'}`;
  }

  const getComponentById = (component: string) => {
    switch (component) {
      case 'LandingScore':
        return <LandingScore />;
      case 'PerformanceScore':
        return <PerformanceScore />;
      case 'CustomTopStats':
        return <CustomTopStats />;
      case 'TimelineChartFiring':
        return <TimelineChartFiring combinedChartsData={combinedChartsData} isFetching={isFetchingCombinedCharts}/>;
      case 'TimelineChartDriving':
        return <TimelineChartDriving combinedChartsData={combinedChartsData} isFetching={isFetchingCombinedCharts}/>;
      case 'MapTimeline':
        return <MapTimeline toggleFullScreen={toggleFullScreen} />;
      case 'LandingScoreLayout2':
        return <OverallScore customChartsData={customChartsData} isFetching={isFetchingCustomCharts}/>;
      case 'TopStats2':
        return <SessionSummary />;
      case 'ResponseTime':
        return <ResponseTime />;
      case 'FireMissions':
        return <FireMissions customChartsData={customChartsData} isFetching={isFetchingCustomCharts}/>;
      case 'EyeTracking':
        return <EyeTracking combinedChartsData={combinedChartsData} isFetching={isFetchingCombinedCharts}/>;
      case 'TimelineChartFlight':
        return <TimelineChartFlight />;
      case 'CockpitTimeline':
        return <CockpitTimeline combinedChartsData={combinedChartsData} isFetching={isFetchingCombinedCharts}/>;
      case 'SemiCircularGauge':
        return <SemiCircularGauge customChartsData={customChartsData} isFetching={isFetchingCustomCharts}/>   
      
      default:
        return null;
    }
  };
  
  return (
    <Page
      pageTitle={false}
      title={title}
      showBackButton={true}
      showDetails={true}
      subtitleDetailText={getSubtitleSessionLabel}
      logoUrl={state?.logoUrl || logoUrl}
    >
      {isFullScreen && (
        <Modal
          closeDisabled={false}
          width="60%"
          height="75%"
          onCloseClick={() => setIsFullScreen(false)}
        >
          <MapTimeline />
        </Modal>
      )}
      <Main>
        <LayoutGrid getComponentById={getComponentById} />
      </Main>
    </Page>
  );
};

const SessionDetailPage = () => (
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="session-detail"
    fallback={<PageLoader />}
  >
    <SessionDetail />
  </VRIntlProviderComponent>
);

export default SessionDetailPage;
