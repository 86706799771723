import moment from "moment";
import { ProgressFilters } from "../../../../enums/overview";
import { convertMillisecondsToTimespan } from "../../../../utils/functions";
import { BarChartDataSet, BarRepresentation, SeriesData } from "../progress-over-time/progress-over-time.types";

export const transformCategories = (day: any, filter: string, year: number | undefined) => {
    switch(filter){
        case ProgressFilters.Last7Days:
        case ProgressFilters.Month:          
            return moment(day).format("DD/MM/YY");
        case ProgressFilters.Lifetime: 
            return day
        case ProgressFilters.Year: 
            return `${day}/${year}`
    }
};

export const transformData = (
    data: BarChartDataSet[] | undefined,
    filter: string,
    year: number | undefined,
    barRepresentation: BarRepresentation
): SeriesData[] => {

    if (!data) {
        return [];
    };

    const score = data?.flatMap((dataSet: BarChartDataSet) => {
        
        let yData: number | null;
        switch (barRepresentation) {
            case BarRepresentation.NumberOfSessions:
                yData = dataSet.numberOfSessions;
                break;
            case BarRepresentation.AverageSessionTime:
                yData = dataSet.averageSessionTime;
                break;
            default:
                /* score is our app's default behaviour */
                yData = dataSet.score;
        };

        return { 
            x: transformCategories(dataSet.day, filter, year),
            y: yData,
            sessions: dataSet.numberOfSessions,
            avgSessionTime: convertMillisecondsToTimespan(dataSet.averageSessionTime),
            score:dataSet.score
        }
    });

    return score;
};


