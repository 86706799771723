import { useCallback, useState, forwardRef } from "react";
import Text from "../text";
import { Area, Container, Error, Field, Label, Wrapper } from "./input.styles";


type Props = {
    label?: string,
    type?: string,
    name: string,
    value?: string | number,
    error?: string | null | undefined,
    height?: string | number,
    as?: string,
    onChange?: (e: any) => void,
    onFocus?: (e: any) => void,
    onBlur?: (e: any) => void,
    placeholder?: string,
    [prop: string]: any,
}

const NativeComponent = forwardRef<HTMLElement, { type: string, [prop: string]: any }>(({ type, ...props }, ref) => {
    switch (type) {
        case 'textarea':
            return <Area ref={ref as React.Ref<HTMLTextAreaElement>} {...props} />
        default:
            return <Field ref={ref as React.Ref<HTMLInputElement>} type={type} {...props} />
    }
});

const Input = forwardRef<HTMLInputElement, Props>(({
    label,
    type,
    name,
    value,
    error,
    height = "3.5rem",
    as = "input",
    ...props
}, ref) => {

    const [isFocus, setIsFocus] = useState(false);

    const handleOnFocus = useCallback((e: any) => {
        setIsFocus(true);
        props.onFocus && props.onFocus(e);
    }, []);
    const handleOnBlur = useCallback((e: any) => {
        setIsFocus(false);
        props.onBlur && props.onBlur(e);
    }, []);

    return <Container {...props}>
        <Wrapper focus={isFocus} error={error} type={as} height={height}>
            {label && <Label focus={isFocus} error={error}>
                <Text variant="xsMedium">{label}</Text>
            </Label>}
            <NativeComponent
                ref={ref}
                type={type || 'text'}
                name={name}
                value={value || ''}
                {...props}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur} />
        </Wrapper>
        {error && <Error><Text variant="xsRegular">{error}</Text></Error>}
    </Container>
});

export const TextArea = ({ as, ...props }: Props) => <Area as="textarea" {...props} />;

export default Input;
