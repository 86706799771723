import { ContentLoaderWrapper } from './content-loader.styles';
import Loader from '../ui/loader';
import { ContentLoaderProps } from './content-loader.types';

const ContentLoader = ({ height }: ContentLoaderProps) => {
  return (
    <ContentLoaderWrapper height={height}>
        <Loader />
    </ContentLoaderWrapper>
  );
};

export default ContentLoader;
