export const SessionDetails = {
    CHART: "Chart",
    COCKPIT: "Cockpit",
    ALTITUDE: "altitude",
    AIRSPEED: "airspeed",
    LOOKOUT: "LookoutProcedure",
    LASER: "Laser",
    FIRINGHIT: "FiringHit",
    FIRINGMISS: "FiringMiss",
    OFFROADEVENTS: "OffRoadEvents",
    HARSHBRAKING: "HarshBraking",
    COLLISIONS: "Collisions",
    SPEED: "Speed",
    BLINDSPOTCHECK: "BlindspotCheck"
}