import client from "../../client";

export const addTrainee = async (email) => {
    const { data } = await client.post('v1/trainee', { emailAddress: email });
    return data;
};

export const addTraineeBulk = async (addresses) => {
    const { data } = await client.post('v1/trainee/bulk', { emailAddresses: addresses });
    return data;
};

//this endpoint fetches trainees with pagination implemented
export const getTrainees = async (token, search, status) => {
    //max pageSize is 15
    const { data } = await client.get(`v1/trainee?pageSize=10${token ? `&skipToken=${token}` : ''}${search ? `&searchString=${encodeURIComponent(search)}` : ''}${status ? `&status=${status}` : ''}`);
    return data;
};

//this endpoint fetches all existing trainees
export const getAllTrainees = async (search) => {
    const { data } = await client.get(`/v1/Trainee/all${search ? `?searchString=${encodeURIComponent(search)}` : ''}`);
    return data;
};

