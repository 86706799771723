import styled from "styled-components";
import base from "../../../../themes/base/base";
import { TableContainer } from "@mui/material";

export const StyledTableContainer = styled(TableContainer)`
    //TableHead
    .MuiTableHead-root {
    }

    //TableHead Wrapper
    .MuiTableCell-root {
        border-bottom: 1px solid ${base.colors.neutral.grey[900]};
    }

    //TableHead
    .MuiTableCell-head {
        ${base.typography.smallMedium};

        background-color: ${base.colors.neutral.grey[700]};
        color: ${base.colors.neutral.grey[50]};
        text-transform: uppercase;
        padding: 0.375rem 1rem;

        &:first-of-type {
            border-top-left-radius: 0.5rem;
        }

        &:last-of-type {
            border-top-right-radius: 0.5rem;
        }
    }

    //shared cell styles - overrides inlineStyles (with sx prop)
    .MuiTableCell-body {
        background-color: ${base.colors.neutral.grey[800]};
        color: ${base.colors.neutral.grey[300]};
    }


    //selected page number
    .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        border: 1px solid ${base.colors.primary.orange[500]};
    }

    //hover page number
    .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root:hover {
        border: 1px solid ${base.colors.neutral.grey[50]};
        background-color: ${base.colors.neutral.grey[500]};
    }

    .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
        background-color: ${base.colors.neutral.grey[500]};
        border: 1px solid ${base.colors.primary.orange[500]};
    }
`;

export const HeadTableCellWithTooltipContent = styled.div`
    display: flex;
    gap: 0.25rem;
    align-items: center;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.5rem;
`;
