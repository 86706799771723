import styled from "styled-components";
import base from "../../../themes/base/base";

export const GlobalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const TitleAndTextContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

interface StatementStylingProps {
    hasScrollableArea?: boolean;
};

export const Statement = styled.div<StatementStylingProps>`
    ${base.typography.baseRegular};
    white-space: pre-wrap;
    width: 100%;
    
    //scrollable area
    overflow-y: auto; //if 'scroll', smaller texts would display scrollbar
    max-height: 35.4375rem;
    padding-right: ${({ hasScrollableArea }) => hasScrollableArea && '0.625rem'};

    /* width */
    ::-webkit-scrollbar {
    width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #262626;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #4D4D4D;
    border-radius: 0.5rem;
    }

    a{
        color: ${base.colors.primary.orange[500]};
        text-decoration: underline;

        &:hover{
            color: ${base.colors.primary.orange[300]};
        }

        &:visited{
            color: ${base.colors.primary.purple[500]};
        }
    }
`;

interface ButtonWrapperStylingProps {
    hasScrollableArea?: boolean;
};

export const ButtonWrapper = styled.div<ButtonWrapperStylingProps>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 22rem;
    margin-top: ${({ hasScrollableArea }) => hasScrollableArea ? "1.5rem" : "2rem"};
`;

export const PolicyTitle = styled.div`
    ${base.typography.extraLargeMedium};  
    border-bottom: 1px solid ${base.colors.primary.orange[500]};
    text-align: center; 
    width: 100%;
`;