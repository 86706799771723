import styled from "styled-components";
import base from "../../../../../themes/base/base";

export const TextInputWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.label`
    ${base.typography.baseMedium};
    height: 3.375rem;
    margin-bottom: 0.625rem;
`;

export const FormInput = styled.input`
    ${base.typography.smallRegular}; 
    background: ${base.colors.neutral.grey[900]};
    color: ${base.colors.neutral.grey[50]};
    padding: 0.5rem 0.75rem;
    border-radius: 2px;
    border: 1px solid transparent;
    outline: none;
    &:focus {
        border: 1px solid #ee7127;
    }
    &.error{
        border: 1px solid ${base.colors.semantic.red[500]};
    }

    /* Override the background and text color for autofill */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        //input background-color
        -webkit-box-shadow: 0 0 0px 1000px ${base.colors.neutral.grey[900]} inset !important;
        //text color
        -webkit-text-fill-color: ${base.colors.neutral.grey[50]} !important;
    }
`;

export const Error = styled.span`
    ${base.typography.smallRegular}; 
    margin-top: 0.25rem;
    color: ${base.colors.semantic.red[500]};
`;