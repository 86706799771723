import styled, { css } from "styled-components";
import base from "../../../../themes/base/base";

export const Score = styled.span<{ $fontSize: string }>`
    font-size: ${props => props.$fontSize};
    display: block;
    font-weight: 400;
    line-height: ${props => props.$fontSize};;
`;

export const TotalScore = styled.span<{ $fontSize: string }>`
    font-weight: 300;
    line-height: ${props => props.$fontSize};
    font-size: ${props => props.$fontSize};
`;

export const ScoreWrapper = styled.div`
    color: '#FFF';
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const scoreContainer = css`
    display: flex;
    border-radius: 8px;
    background: ${base.colors.neutral.grey[800]};
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
`

export const Section = {
    Container: styled.div`
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
        width: 100%;
        overflow-x: hidden;
    `,

    SubContainer: styled.div`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        flex: 1;
    `,

    FiringScore: styled.div`
        ${scoreContainer}  
        flex: 3.3;
    `,

    BreakDownContainer: styled.div`
        display: flex;
        gap: 1.5rem;
        align-items: center;
        flex-wrap: wrap;
    `,

    Breakdown: styled.div`
        ${scoreContainer}  
        flex: 6.7;
        gap: 5.5rem;
        height: 24rem;
    `,

    NoData: styled.div`
        ${scoreContainer} 
        flex: 1;
        height: 25rem; 
    `,

    NoDataContent: styled.div`
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
    `,

    Description: styled.span`
        color: #999;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.675rem; 
        letter-spacing: 0.01rem;
        text-align: center;
    `,
}






    


