import Gauge from "./gauge";
import { Container, Legend, LegendIcon, LegendText, LegendWrapper } from "./gauge-styles";
import { Title } from "../../session-detail.styles";
import Tooltip from "../../../../components/ui/tooltip/tooltip";
import EmptyState from "./empty-state";
import ContentLoader from "../../../../components/content-loader/content-loader";
import { CustomChartsDataProps } from "../../api/fetchCustomChartsData";

interface LegendProps {
    min: number;
    max: number;
    color: string;
    text: string;
}

export const SemiCircularGauge = ({customChartsData, isFetching}: CustomChartsDataProps) => {

    const cognitiveDataset = customChartsData?.find((dataSet: any) => dataSet.chartType === "Gauge");
    const cognitiveData = cognitiveDataset?.datasets[0];

    const legend = cognitiveDataset?.legend?.items.sort((a: LegendProps, b: LegendProps) => a.max - b.max);
    const min = legend?.[0].min;
    const max = legend?.[legend.length - 1].max;
    const colors = legend?.map((item:LegendProps) => item.color);
    const value = Math.round(cognitiveData?.Score);

    const formatLabel = () => {
        const valueLegend = legend.find((r: LegendProps) => value >= r.min && value <= r.max);
        const label = valueLegend ? valueLegend.text : '';
        const color = valueLegend ? valueLegend.color : '';
        return {label, color}
    }

    if(isFetching) return <ContentLoader height="21.0625rem" />;

    return (
        <Container>
            <Title>
                {cognitiveDataset?.description}
                <Tooltip text="session-detail:cognitive-gauge-tooltip" />
            </Title>

            {!cognitiveData ? <EmptyState /> :
                <>
                    <Gauge 
                        value={value} 
                        formatLabel={formatLabel}
                        min={min}
                        max={max}
                        colors={colors}
                    />

                    <LegendWrapper>
                        {legend.map((item: LegendProps) => {
                            return (
                                <Legend key={item.max}>
                                    <LegendIcon color={item.color}></LegendIcon>
                                    <LegendText>{item.text}</LegendText>
                                </Legend>
                            )}
                        )}
                    </LegendWrapper>
                </>
            }        
        </Container> 
    )
}
