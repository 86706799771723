import styled, { css } from "styled-components";
import Chart from "react-apexcharts";
import base from "../../../../themes/base/base";

export const Container = styled.div`
  display: flex;
  border-radius: 8px;
  background: ${base.colors.neutral.grey[800]};
  flex-direction: column;
  padding: 1.5rem 1.5rem 0;

  svg {
    pointer-events: all;
  }
`;

export const Label = styled.label`
  ${base.typography.smallMedium};

  display: flex;
  align-items: center;
  gap: 0.125rem;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
    cursor: auto;
  }
`;

export const TitleAndRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 0.75rem;

  .no-lookout{
    visibility: hidden;
  }

  .unchecked{
    font-weight: 400;
  }
`;

export const tooltipStyles = css`
  border-radius: 2px;
  padding: 8px 8px;
  background-color: #4D4D4D;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    color: #eee;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
  }

  span.value-label{
    margin-right: 4px;
  }

  span.no-icon{
    margin-left: 28px;
  }

  span.hidden{
    display: none;
  }
  
  img {
    vertical-align: middle;
    margin: 0 5px 4px 0;
  }

  img.timeline {
    margin: 0px 2px 2px 1px;
  }
`;

export const CustomAreaChart = styled(Chart)`
  margin-bottom: 7px;
  .apexcharts-tooltip {
    border: none !important;
    box-shadow: none !important;
  }

  .custom-tooltip {
    ${tooltipStyles}
  }
`;
