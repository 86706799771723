import styled, { css } from "styled-components";
import Chart from "react-apexcharts";

export const ChartContainer = styled.div`
  display: flex;
  flex: 0.8;  

  svg{
    pointer-events: all;
  }
`;  

export const tooltipStyles = css`
  border-radius: 2px;
  padding: 10px 8px;
  background-color: #53555a;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  span {
    color: #eee;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
`;

export const CustomColumnChart = styled(Chart)`
  width: 100%;
  min-height: 16.1875rem !important;

  &.month .apexcharts-xaxis-texts-g .apexcharts-xaxis-label:nth-child(even){
    visibility: hidden;
  }

  .apexcharts-tooltip {
    border: none !important;
    box-shadow: none !important;
  }
  
  .custom-tooltip {
    ${tooltipStyles}
  }

`;