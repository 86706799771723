import styled, { css } from "styled-components";
import base from "../../../../../themes/base/base";

export const SelectWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.label`
    ${base.typography.baseMedium};
    height: 3.375rem;
    margin-bottom: 0.625rem;
`;

export const DropdownMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

interface StylingProps {
    isOpen: boolean;
}

export const PlaceholderWrapper = styled.div<StylingProps>`
    background-color: ${base.colors.neutral.grey[900]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 2px;
    ${base.typography.smallRegular};

    ${({ isOpen }) =>
        isOpen &&
        css`
            border: 1px solid ${base.colors.primary.orange[500]};
        `}

    &.error {
        border: 1px solid ${base.colors.semantic.red[500]};
    }
`;

export const Placeholder = styled.span`
    color: ${base.colors.neutral.grey[500]};
`;

export const SelectedOption = styled.span`
    &.error {
        border: 1px solid ${base.colors.semantic.red[500]};
    }
`;

export const OptionsWrapper = styled.div`
    position: absolute;
    top: 2.875rem;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${base.colors.neutral.grey[900]};
    border-radius: 2px;
    border: 1px solid ${base.colors.neutral.grey[300]};
    z-index: 1;
`;

interface MenuItemStylingProps {
    isSelected: boolean;
}

export const MenuItem = styled.option<MenuItemStylingProps>`
    display: flex;
    width: 100%;
    white-space: nowrap;
    ${base.typography.smallRegular};
    padding: 0.5rem 1.25rem 0.5rem 0.75rem;

    ${({ isSelected }) =>
        isSelected &&
        css`
            padding: 0.5rem 1.25rem 0.5rem 0.75rem;
            ${base.typography.smallMedium};
            color: ${base.colors.primary.orange[500]};
        `}

    &:hover {
        cursor: pointer;
        background-color: ${base.colors.neutral.grey[600]};
        color: ${base.colors.primary.orange[50]};
    }

    &:active {
        ${base.typography.smallMedium};
        padding: 0.5rem 1.25rem 0.5rem 0.75rem;
        color: ${base.colors.primary.orange[500]};
    }
`;

export const Error = styled.span`
    ${base.typography.smallRegular};
    margin-top: 0.25rem;
    color: ${base.colors.semantic.red[500]};
`;
