import { NavLink } from "react-router-dom";
import styled, { css, DefaultTheme } from "styled-components";
import base from "../../themes/base/base";
import Text from "../ui/text";
import Avatar from "../ui/avatar";
import { Initials } from "../ui/avatar/avatar.styles";
import Loader from "../ui/loader";

interface ContainerStylingProps {
  backgroundColor?: string;
}

export const Container = styled.div<ContainerStylingProps>`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor
    ? `${backgroundColor}`
    : `${base.colors.neutral.grey[800]}`};
  border-radius: 0.125rem;

  @media (max-width: 884px) {
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: ${base.colors.neutral.grey[600]};
  }
`;

interface ToggleStylingProps {
    fullWidth?: boolean;
    isOpen?: boolean;
    backgroundColor?: string;
}

export const Toggle = styled.div<ToggleStylingProps>`
  ${base.typography.smallRegular};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  border-radius: 2px;
  padding: 0.4375rem 0.75rem;
  background-color: ${({ isOpen }) => isOpen && `${base.colors.neutral.grey[800]}`};

  //if there's a backgroundColor passed, it will be used, or else, use the default one
  //if the Toggle is open, use orange border
  border: ${({ backgroundColor, isOpen }) => {
    if (isOpen) {
      return `1px solid ${base.colors.primary.orange[500]}`;
    } else {
      return backgroundColor ? `1px solid ${backgroundColor}` : `1px solid ${base.colors.neutral.grey[800]}`;
    }
  }};

  //use default hover color
  &:hover {
    border: ${({ isOpen }) => !isOpen && `1px solid ${base.colors.neutral.grey[600]}`};
    background-color: ${base.colors.neutral.grey[600]};
  }

  ${({ fullWidth }) => fullWidth
    ? css`
      width: 100%;
      justify-content: space-between;
    ` 
    : css`
      max-width: 16.125rem;
    `
  }

  @media (max-width: 1023px) {
    padding: 0;
  } 
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`;

export const PopUp = styled.div<{ width: string | number }>`
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  width: ${({ width }) => width};
  border: 0;
  border-radius: 2px;
  background-color: ${base.colors.neutral.grey[800]};
  z-index: 9999;
`;

export const DateFilterPopUp = styled(PopUp)`
  border: 1px solid ${base.colors.neutral.grey[300]};
  width: 27.75rem;

  @media (max-width: 1023px) {
    max-width: 385px;
  }
`;

export const TraineesPopUp = styled(PopUp)`
  display: flex;
  flex-direction: column;
  background-color: ${base.colors.neutral.grey[900]};
  gap: 1px;
  z-index: 99999;
  border: 1px solid ${base.colors.neutral.grey[300]};
  width: 16.125rem;
`;

export const SearchBarDiv = styled.div`
  display: flex;
  padding: 0.75rem;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: ${base.colors.neutral.grey[800]};
`;

export const AllTrainees = styled.div<{ selected: boolean }>`
  ${base.typography.smallRegular};

  display: flex;
  padding: 0.5rem 0.75rem;
  background-color: ${base.colors.neutral.grey[800]};
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  ${({ selected }) => selected && css`
    ${base.typography.smallMedium};
    color: ${base.colors.primary.orange[500]};
  `};

  &:hover {
    background-color: ${base.colors.neutral.grey[600]};
  }
`;

export const AllTraineesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const AvatarContainer = styled.div`
  & svg path {
    fill: #121212;
  }
`;

export const AvatarIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.primary.orange[500]};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
`;

export const StyledAvatar = styled(Avatar)`
  ${Initials} {
    ${base.typography.extraSmallRegular};
  };
`;

export const ListContainer = styled.div`
  max-height: 10rem;
  overflow-x: hidden;
  border-bottom-left-radius: 2px;
  background-color: ${base.colors.neutral.grey[800]};

  /* width */
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* handle */
  &::-webkit-scrollbar-thumb {
    background-color: #4D4D4D;
    border-radius: 0.5rem;
  }

  /* track */
  ::-webkit-scrollbar-track {
    background: #262626;
    border-bottom-right-radius: 2px;
  }
`;

export const List = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${base.colors.neutral.grey[800]};
  z-index: 99;
`;

type ItemProps = {
  isSearch?: boolean;
  selected?: boolean;
};

export const Item = styled.li<ItemProps>`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 0.5rem 0.75rem;

  &:hover {
    background-color: ${base.colors.neutral.grey[600]};
    cursor: pointer;
  }
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

export const Name = styled.div`
  ${base.typography.smallRegular};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`;

interface TextStylingProps {
  selected?: boolean;
};

export const StyledText = styled(Text)<TextStylingProps>`

  ${base.typography.smallRegular};

  ${({ selected }) => selected && css`
    ${base.typography.smallMedium};
    color: ${base.colors.primary.orange[500]};
  `}
`;

export const TextSpan = styled.span`
  ${base.typography.smallRegular};
`;

export const LoaderContainer = styled.li`
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLoader = styled(Loader)`
  font-size: 1.75rem;
`;
