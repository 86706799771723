import styled, { css } from "styled-components";
import Text from "../../../../components/ui/text";
import { desktopLargeMedia } from "../../../../themes/breakpoints";
import base from "../../../../themes/base/base";

export const SessionCardWrapperDiv = styled.a`
    background-color: ${({ theme }) => theme.colors.neutral.grey[800]};
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${({ theme }) => theme.colors.neutral.grey[50]};
    text-decoration: none;
    user-select: none;

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.grey[600]};
        cursor: pointer;
    }
`;

export const SecondaryWrapperDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto 0 auto 0.5rem;
    gap: 0.375rem;

    ${desktopLargeMedia} {
        margin: auto 0 auto 1rem;
        gap: 0.75rem;
    };
`;

export const ContentWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;

export const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
`;

export const StyledImage = styled.img``;

export const TitleWrapper = styled.div`
    display: grid;
    grid-template-columns: auto minmax(1, 1fr);
    gap: 0.5rem;
`;

export const SimulationName = styled(Text)`
    ${base.typography.largeMedium};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ScenarioName = styled(Text)`
    ${base.typography.largeMedium};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const BulletPoint = styled(Text)`
    ${base.typography.largeMedium};
`;

export const ScenarioAndBulletPointWrapper = styled.div`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 0.5rem;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const UserInfoWrapper = styled.div`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 0.25rem;
    align-items: center;
`;

export const MultipleUsersDiv = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

const sharedDateDurationAndTimeStyles = css`
    ${base.typography.baseRegular};

    color: ${base.colors.neutral.grey[300]};
`;

export const TruncatedStyledText = styled(Text)`
    ${sharedDateDurationAndTimeStyles};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const PlusSpan = styled.span`
    ${base.typography.extraSmallRegular};

    color: ${base.colors.neutral.grey[900]};
    background-color: ${base.colors.neutral.grey[300]};
    border-radius: 24px;
    padding: 0 0.375rem;
`;

export const DateTimeAndDurationWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

export const DateText = styled(Text)`
    ${sharedDateDurationAndTimeStyles};
`;

export const TimeWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

export const TimeText = styled(Text)`
    ${sharedDateDurationAndTimeStyles};
`;

export const DurationWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const DurationText = styled(Text)`
    ${sharedDateDurationAndTimeStyles};
`;

export const ArrowWrapper = styled.div`
    align-self: center;
`;
