import React, { HTMLProps } from 'react';
import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  flex-direction: column;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: #121212;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })<React.InputHTMLAttributes<HTMLInputElement>>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? props.theme.colors.primary.orange[500] : props.theme.colors.neutral.grey[800])};
  border: 1px solid ${props => (props.checked ? props.theme.colors.primary.orange[500] : props.theme.colors.neutral.grey[50])};
  border-radius: 1px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${props => props.theme.colors.primary.orange[500]};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  className?: string;
  checked: boolean;
}

export const Checkbox = ({ className, checked, ref, ...props }: CheckboxProps) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox defaultChecked={checked} />
    <StyledCheckbox 
      checked={checked} 
      className={`styled-checkbox ${checked ? 'checked' : 'unchecked'}`}
    >
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;