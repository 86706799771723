import { IconProps } from "../../../types/icon";

const EmptySessionsState = ({
    width = 128,
    height = 128,
}: IconProps) => (
    <div data-test-id="empty_sessions">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 128 128"
            fill="none"
        >
            <g clipPath="url(#clip0_9645_54854)">
                <mask id="mask0_9645_54854" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="128">
                    <path d="M128 0H0V128H128V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_9645_54854)">
                    <path
                        d="M74.0662 121.135L73.4763 117.708H73.4537L73.1812 116.165C70.2037 116.71 67.1113 116.97 63.9963 116.97C57.6313 116.97 51.5288 115.835 45.8763 113.77L45.3338 115.245L44.7212 116.948L44.1437 118.513L42.7287 122.415L42.0938 124.14C48.9212 126.648 56.2937 127.998 63.9837 127.998C67.7662 127.998 71.4812 127.668 75.0937 127.045L74.7662 125.23L74.0637 121.135H74.0662Z"
                        fill="#121212"
                    />
                    <path
                        d="M96.3177 110.095L95.2752 108.607L94.3802 107.337C93.1227 108.232 91.8102 109.062 90.4727 109.857L91.2652 111.207L92.1702 112.785L92.6902 113.67L93.2227 114.6L94.0827 116.097L95.0802 117.835L95.9977 119.422C97.6177 118.47 99.1802 117.47 100.71 116.405L97.2777 111.47L96.3252 110.107L96.3127 110.095H96.3177Z"
                        fill="#121212"
                    />
                    <path
                        d="M127.753 58.2876L121.795 58.8101L120.435 58.9351L120.13 58.9576L118.33 59.1176V59.1401L116.768 59.2651C116.893 60.7976 116.96 62.3401 116.96 63.8826C116.96 65.4251 116.893 67.0251 116.768 68.5801L122.18 69.1701V69.1476L123.9 69.3301V69.2851L125.883 69.5001V69.4776L127.718 69.6701C127.888 67.7526 127.99 65.8226 127.99 63.8726C127.99 61.9226 127.9 60.1276 127.74 58.2901H127.75L127.753 58.2876Z"
                        fill="#121212"
                    />
                    <path
                        d="M121.126 73.9675L119.484 73.6825L117.706 73.365L116.166 73.0925C114.806 80.875 111.739 88.08 107.379 94.31L108.669 95.205L111.861 97.4525L113.266 98.44V98.43L114.909 99.5875L116.391 100.643C121.679 93.1075 125.384 84.405 127.024 75.0225L125.211 74.6925L121.124 73.9675H121.126Z"
                        fill="#121212"
                    />
                    <path
                        d="M108.413 101.223L107.133 100.155L105.751 98.9975L104.551 97.9875C103.566 99.18 102.523 100.303 101.426 101.403L105.288 105.273L106.511 106.498L107.916 107.905L109.218 109.21C110.531 107.893 111.801 106.51 113.001 105.103L111.608 103.923L108.426 101.245L108.413 101.223Z"
                        fill="#121212"
                    />
                    <path
                        d="M89.4206 118.515L88.7406 117.062L88.5031 116.562L88.3456 116.232L87.0331 113.418L86.3656 112C83.6031 113.293 80.6931 114.37 77.7031 115.165L78.1006 116.675L78.8031 119.285L78.9956 120.035L80.0831 124.075L80.5581 125.835C84.1831 124.882 87.6806 123.578 91.0331 122.01L90.2506 120.342L89.4006 118.527L89.4231 118.518L89.4206 118.515Z"
                        fill="#121212"
                    />
                    <path
                        d="M2.06273 58.4573L5.77773 58.7748L11.2127 59.2523C11.4852 56.1423 12.0402 53.0923 12.8102 50.1398L11.3052 49.7423L9.55023 49.2648L8.52023 48.9923L7.54523 48.7323L5.88023 48.2773L3.94273 47.7548L2.16523 47.2773C1.21273 50.8398 0.557734 54.5273 0.240234 58.2948L2.07523 58.4423V58.4648L2.06273 58.4523V58.4573Z"
                        fill="#121212"
                    />
                    <path
                        d="M7.44258 43.2649L7.95258 43.4474L9.45758 44.0024L11.0201 44.5799L12.7176 45.2049L14.1901 45.7499C15.2651 42.7874 16.6126 39.9399 18.1751 37.2524L13.5326 34.3599C13.5326 34.3599 13.5101 34.3824 13.5101 34.3949L12.0376 33.4749C12.0376 33.4749 12.0151 33.5099 12.0151 33.5199L10.3176 32.4649C10.3176 32.4649 10.2951 32.4874 10.2951 32.4999L8.73258 31.5249C6.80758 34.8374 5.16508 38.3099 3.83008 41.9524L5.56258 42.5874L7.43008 43.2799L7.44258 43.2674V43.2649Z"
                        fill="#121212"
                    />
                    <path
                        d="M17.9311 25.1549L19.2561 26.2674L22.2336 28.7649L23.4336 29.7749C25.4036 27.4149 27.6011 25.2249 29.9686 23.2499L28.9611 22.0474L27.7936 20.6524L26.7286 19.3699L24.0561 16.1924L22.8786 14.7749C20.0361 17.1699 17.3986 19.8024 14.9961 22.6724L16.4111 23.8524L17.9411 25.1349L17.9286 25.1574L17.9311 25.1549Z"
                        fill="#121212"
                    />
                    <path
                        d="M29.5019 14.5348L30.4869 15.9523L32.7294 19.1298L33.6244 20.4223C39.8294 16.0323 47.0319 12.9798 54.8019 11.6173L54.5294 10.0748L54.2019 8.24734L53.9194 6.62484L53.1944 2.52984L52.8669 0.714844C43.4894 2.35984 34.7944 6.06984 27.2969 11.3698L28.3494 12.8798L29.5044 14.5248V14.5373L29.5019 14.5348Z"
                        fill="#121212"
                    />
                    <path
                        d="M20.6129 94.3125L19.3329 95.2075L17.8504 96.2525L16.4904 97.205L13.0929 99.6L11.5879 100.655C13.7279 103.717 16.1279 106.567 18.7679 109.2L20.0704 107.895L21.4854 106.477L22.7079 105.252L26.5704 101.382C24.3954 99.205 22.4029 96.8325 20.6354 94.3025L20.6129 94.315V94.3125Z"
                        fill="#121212"
                    />
                    <path
                        d="M94.3588 20.435L95.2538 19.1425L97.4963 15.965L98.4813 14.5475L99.6363 12.9125L100.689 11.4025C91.7313 5.0725 81.0863 0.9975 69.5688 0L69.0363 5.9675L68.7188 9.45H68.7413L68.5938 11.015C78.1187 11.8425 86.9288 15.2025 94.3463 20.4325H94.3588V20.435Z"
                        fill="#121212"
                    />
                    <path
                        d="M116.679 49.755L119.509 48.9825L120.041 48.8475L124.051 47.7575L125.829 47.28C122.874 36.2625 117.064 26.415 109.226 18.5725L105.014 22.7925L103.824 23.9725L102.544 25.255L101.434 26.3675C107.911 32.8575 112.736 41.015 115.181 50.1375L116.686 49.74L116.676 49.7525L116.679 49.755Z"
                        fill="#121212"
                    />
                    <path
                        d="M28.9614 105.737L27.7939 107.122L27.3514 107.645L26.4464 108.722L25.6089 109.732L24.0564 111.582L22.8789 113C25.7214 115.395 28.7789 117.527 32.0064 119.422L32.9239 117.835L33.9214 116.097L34.7814 114.6L35.3939 113.545L35.8464 112.772L36.7514 111.195L37.5439 109.845C34.8714 108.28 32.3464 106.497 29.9914 104.522L28.9839 105.725L28.9614 105.737Z"
                        fill="#121212"
                    />
                    <path
                        d="M14.5662 86.9827L15.9812 86.3127C15.3362 84.9277 14.7462 83.4877 14.2037 82.0352L12.7312 82.5802L11.0337 83.1926L9.47125 83.7701L3.84375 85.8127C4.48875 87.5727 5.21375 89.2952 5.99625 90.9752L7.66125 90.2151L9.76875 89.2277L11.0363 88.6376L14.5687 86.9802L14.5662 86.9827Z"
                        fill="#121212"
                    />
                    <path
                        d="M4.735 74.3649L6.445 74.0599L10.285 73.3799L11.825 73.1074C11.305 70.1224 11.02 67.0724 11.02 63.9399L9.4575 63.9624V63.9399L7.645 63.9624V63.9399L5.98 63.9624V63.9399L0 64.0199C0 67.7649 0.3275 71.4399 0.9525 75.0374L2.765 74.7074L4.725 74.3549V74.3649H4.7375H4.735Z"
                        fill="#121212"
                    />
                </g>
                <g filter="url(#filter0_d_9645_54854)">
                    <path
                        d="M121.956 29.25H6.27126C5.56916 29.25 5 29.8192 5 30.5213V48.3189C5 49.021 5.56916 49.5902 6.27126 49.5902H121.956C122.658 49.5902 123.227 49.021 123.227 48.3189V30.5213C123.227 29.8192 122.658 29.25 121.956 29.25Z"
                        fill="#4D4D4D"
                    />
                </g>
                <path
                    d="M67.0605 33.709H11.7607C10.181 33.709 8.90039 34.8283 8.90039 36.209C8.90039 37.5897 10.181 38.709 11.7607 38.709H67.0605C68.6403 38.709 69.9209 37.5897 69.9209 36.209C69.9209 34.8283 68.6403 33.709 67.0605 33.709Z"
                    fill="#999999"
                />
                <path
                    d="M41.4034 41.3145H16.4422C15.4364 41.3145 14.6211 42.1696 14.6211 43.2245V43.4045C14.6211 44.4593 15.4364 45.3145 16.4422 45.3145H41.4034C42.4091 45.3145 43.2244 44.4593 43.2244 43.4045V43.2245C43.2244 42.1696 42.4091 41.3145 41.4034 41.3145Z"
                    fill="#999999"
                />
                <path
                    d="M71.9151 41.3145H46.9539C45.9481 41.3145 45.1328 42.1696 45.1328 43.2245V43.4045C45.1328 44.4593 45.9481 45.3145 46.9539 45.3145H71.9151C72.9208 45.3145 73.7362 44.4593 73.7362 43.4045V43.2245C73.7362 42.1696 72.9208 41.3145 71.9151 41.3145Z"
                    fill="#999999"
                />
                <path
                    d="M12.9004 43.3145C12.9004 42.2099 12.005 41.3145 10.9004 41.3145C9.79582 41.3145 8.90039 42.2099 8.90039 43.3145C8.90039 44.419 9.79582 45.3145 10.9004 45.3145C12.005 45.3145 12.9004 44.419 12.9004 43.3145Z"
                    fill="#999999"
                />
                <g filter="url(#filter1_d_9645_54854)">
                    <path
                        d="M121.956 53.4038H6.27126C5.56916 53.4038 5 53.973 5 54.6751V72.4727C5 73.1748 5.56916 73.744 6.27126 73.744H121.956C122.658 73.744 123.227 73.1748 123.227 72.4727V54.6751C123.227 53.973 122.658 53.4038 121.956 53.4038Z"
                        fill="#4D4D4D"
                    />
                </g>
                <path
                    d="M67.0605 57.863H11.7607C10.181 57.863 8.90039 58.9823 8.90039 60.363C8.90039 61.7437 10.181 62.863 11.7607 62.863H67.0605C68.6403 62.863 69.9209 61.7437 69.9209 60.363C69.9209 58.9823 68.6403 57.863 67.0605 57.863Z"
                    fill="#999999"
                />
                <path
                    d="M41.4034 65.4685H16.4422C15.4364 65.4685 14.6211 66.3236 14.6211 67.3785V67.5585C14.6211 68.6134 15.4364 69.4685 16.4422 69.4685H41.4034C42.4091 69.4685 43.2244 68.6134 43.2244 67.5585V67.3785C43.2244 66.3236 42.4091 65.4685 41.4034 65.4685Z"
                    fill="#999999"
                />
                <path
                    d="M71.9151 65.4685H46.9539C45.9481 65.4685 45.1328 66.3236 45.1328 67.3785V67.5585C45.1328 68.6134 45.9481 69.4685 46.9539 69.4685H71.9151C72.9208 69.4685 73.7362 68.6134 73.7362 67.5585V67.3785C73.7362 66.3236 72.9208 65.4685 71.9151 65.4685Z"
                    fill="#999999"
                />
                <path
                    d="M12.9004 67.4685C12.9004 66.3639 12.005 65.4685 10.9004 65.4685C9.79582 65.4685 8.90039 66.3639 8.90039 67.4685C8.90039 68.5731 9.79582 69.4685 10.9004 69.4685C12.005 69.4685 12.9004 68.5731 12.9004 67.4685Z"
                    fill="#999999"
                />
                <g filter="url(#filter2_d_9645_54854)">
                    <path
                        d="M121.956 77.5579H6.27126C5.56916 77.5579 5 78.127 5 78.8291V96.6268C5 97.3289 5.56916 97.898 6.27126 97.898H121.956C122.658 97.898 123.227 97.3289 123.227 96.6268V78.8291C123.227 78.127 122.658 77.5579 121.956 77.5579Z"
                        fill="#4D4D4D"
                    />
                </g>
                <path
                    d="M67.0605 82.0168H11.7607C10.181 82.0168 8.90039 83.1361 8.90039 84.5168C8.90039 85.8976 10.181 87.0168 11.7607 87.0168H67.0605C68.6403 87.0168 69.9209 85.8976 69.9209 84.5168C69.9209 83.1361 68.6403 82.0168 67.0605 82.0168Z"
                    fill="#999999"
                />
                <path
                    d="M41.4034 89.6223H16.4422C15.4364 89.6223 14.6211 90.4775 14.6211 91.5323V91.7123C14.6211 92.7672 15.4364 93.6223 16.4422 93.6223H41.4034C42.4091 93.6223 43.2244 92.7672 43.2244 91.7123V91.5323C43.2244 90.4775 42.4091 89.6223 41.4034 89.6223Z"
                    fill="#999999"
                />
                <path
                    d="M71.9151 89.6223H46.9539C45.9481 89.6223 45.1328 90.4775 45.1328 91.5323V91.7123C45.1328 92.7672 45.9481 93.6223 46.9539 93.6223H71.9151C72.9208 93.6223 73.7362 92.7672 73.7362 91.7123V91.5323C73.7362 90.4775 72.9208 89.6223 71.9151 89.6223Z"
                    fill="#999999"
                />
                <path
                    d="M12.9004 91.6223C12.9004 90.5177 12.005 89.6223 10.9004 89.6223C9.79582 89.6223 8.90039 90.5177 8.90039 91.6223C8.90039 92.7269 9.79582 93.6223 10.9004 93.6223C12.005 93.6223 12.9004 92.7269 12.9004 91.6223Z"
                    fill="#999999"
                />
            </g>
            <defs>
                <filter id="filter0_d_9645_54854" x="3" y="29.25" width="122.227" height="24.3401" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9645_54854" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9645_54854" result="shape" />
                </filter>
                <filter
                    id="filter1_d_9645_54854"
                    x="3"
                    y="53.4038"
                    width="122.227"
                    height="24.3401"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9645_54854" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9645_54854" result="shape" />
                </filter>
                <filter
                    id="filter2_d_9645_54854"
                    x="3"
                    y="77.5579"
                    width="122.227"
                    height="24.3401"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9645_54854" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9645_54854" result="shape" />
                </filter>
                <clipPath id="clip0_9645_54854">
                    <rect width="128" height="128" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </div>
);

export default EmptySessionsState;
