import styled, { css } from "styled-components";
import base from "../../../../themes/base/base";
import Avatar from "../../../../components/ui/avatar";

export const DefaultTableCellContent = styled.div`
    ${base.typography.smallRegular};

    color: ${base.colors.neutral.grey[300]};
`;

export const DotsIconWrapper = styled.div<{ isOpen: boolean }>`
    display: flex;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid transparent;
    position: relative;
    width: 100%;
    user-select: none;

    ${({ isOpen }) =>
        isOpen
            ? css`
                  border: 1px solid ${base.colors.primary.orange[300]};
                  background-color: ${base.colors.neutral.grey[500]};
              `
            : css`
                  &:hover {
                      background-color: ${base.colors.neutral.grey[500]};
                  }

                  &:active {
                      border: 1px solid ${base.colors.primary.orange[300]};
                      background-color: ${base.colors.neutral.grey[500]};
                  }
              `}
`;

export const NameTableCell = styled.div`
    display: flex;
    gap: 1rem;
`;

export const NameAndEmailWrapper = styled.div`
    display: grid; //display grid makes overflow: hidden with ellipsis be responsive
    grid-template-columns: auto minmax(1, 1fr);
`;

export const NameTableCellContent = styled.div`
    ${base.typography.baseRegular};

    color: ${base.colors.neutral.grey[50]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const EmailTableCellContent = styled.div`
    ${base.typography.smallRegular};

    color: ${base.colors.neutral.grey[300]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledAvatar = styled(Avatar)`
    cursor: default;

    &:hover {
        box-shadow: none;
    }
`;
