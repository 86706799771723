import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import { CSVLink } from 'react-csv';
import Page from "../../components/ui/page";
import { getReports } from "../../api/dashboard";
import {
    Container,
    Text,
    Title,
    FileTypeToggle,
    ToggleWrapper,
    Wrapper,
    LoaderContainer
} from "./reports.styles";
import {
    ChevronDownSquareCornersIcon24,
    DownloadIcon24,
    FileIcon24
} from "../../components/icons";
import Loader from "../../components/ui/loader";
import { transformData, Headers } from "./utils";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import TraineesFilter from "../../components/filters/trainees";
import DateFilter from "../../components/filters/date";
import base from "../../themes/base/base";
import { PrimaryButton } from "../shared/shared.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const Reports = () => {  
    const history = useHistory();
    const downloadCSV = useRef<CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}>(null);
    const queryParams = new URLSearchParams(window.location.search);
    const dateRange = queryParams.get('dateRange');
    const currentPath = `${history.location.pathname}?dateRange=last7days`;

    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [filters, setFilters] = useState<{
        userId: string | null,
        from: Date,
        to: Date
    }>({
        userId: null,
        from: new Date(),
        to: new Date()
    })

    const handleOnClick = () => {
        setOpen(!open);
    };

    const handleDateChange = (from: Date, to: Date) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            from,
            to
        }));
    };

    const handleTraineeChange = (user: string | null) => {
        setFilters(prevFilters => ({
            ...prevFilters,
           userId: user
        }));
    };

    const handleDownload = async () => {
        setDisabled(true);
        const data = await getReports(filters);
        setData(data);
        handleTraineeChange(null);
        setDisabled(false);
    }

    const getFileName = () => {
        const currentDate = new Date();
        return `SessionList_${moment(currentDate).format('MMMM D, YYYY HH:mm:ss')}.csv`;
      };

    useEffect(() => {
        if(data && downloadCSV.current) {
            downloadCSV.current.link.click();
        }

        history.replace(currentPath);
    }, [data]);

    return (
        <VRIntlProviderComponent localeFn={localeFn} id="reports-page" fallback={null}>
            <Page title="Reports">
                <Wrapper>
                    <Container>
                        <Title>
                            <FormattedMessage id={"reports:container-title"} />
                        </Title>

                        <ToggleWrapper>
                            <FileTypeToggle fullWidth disabled>
                                <FileIcon24 />
                                <Text>Sessions Log - CSV file</Text>
                                <ChevronDownSquareCornersIcon24 />
                            </FileTypeToggle>

                            <TraineesFilter
                                isOpen={open}
                                backgroundColor={base.colors.neutral.grey[900]}
                                handleOnClick={handleOnClick}
                                onUserSelected={handleTraineeChange} 
                                initialUserId={filters.userId}
                                fullWidth
                                />

                            <DateFilter
                                isOpen={open}
                                backgroundColor={base.colors.neutral.grey[900]}
                                handleOnClick={handleOnClick} 
                                onChange={handleDateChange} 
                                initialDateRange={{from:filters.from, to: filters.to}} 
                                dateRange={dateRange}
                                fullWidth
                            />

                        </ToggleWrapper>

                        <PrimaryButton
                            fullWidth
                            onClick={handleDownload}
                            disabled={disabled}
                        >
                            {disabled ? (
                                <LoaderContainer>
                                    <Loader size="medium" color="#666666" fontSize="1.5rem"/>
                                </LoaderContainer>
                            ) : ( 
                                <>
                                    <DownloadIcon24 fill='#121212' />
                                    Download Report
                                </>
                            )}
                        </PrimaryButton> 
                        <CSVLink 
                            ref={downloadCSV} 
                            data={transformData(data)} 
                            headers={Headers} 
                            filename= {getFileName()}
                            target="_blank">
                         </CSVLink>

                    </Container>
                </Wrapper>
            </Page>
        </VRIntlProviderComponent>
    )
}

export default Reports;