import { SessionCardProps } from "./session-card.types";
import {
    ArrowWrapper,
    LogoWrapper,
    InfoWrapper,
    SessionCardWrapperDiv,
    StyledImage,
    UserInfoWrapper,
    DateTimeAndDurationWrapper,
    DateWrapper,
    TimeWrapper,
    DurationWrapper,
    ContentWrapper,
    TitleWrapper,
    SecondaryWrapperDiv,
    TruncatedStyledText,
    SimulationName,
    ScenarioName,
    ScenarioAndBulletPointWrapper,
    BulletPoint,
    DateText,
    DurationText,
    TimeText,
    MultipleUsersDiv,
    PlusSpan,
} from "./session-card.styles";
import Time from "../../../../components/icons/time";
import { getTimeDifference } from "../sessions/utils";
import { useHistory } from "react-router-dom";
import { CalendarIcon24, CardArrowIcon48, UsersIcon24 } from "../../../../components/icons";
import { useQuery } from "react-query";
import { getCustomerSettings } from "../../../../api/settings";
import useMediaQuery from "../../../../utils/hooks/use-media-query";
import moment from "moment";

const SessionCard = ({ sessionInfo, scenarioInfo, index }: SessionCardProps) => {
    const history = useHistory();

    const isDesktopLargeMedia = useMediaQuery("(min-width: 1920px)");

    const { id, traineeName, simulationName, logoUrl, userId } = sessionInfo;
    const { simulationId, scenarioName, scenarioInstanceId, from, to } =
        scenarioInfo;

    const duration = (from: any, to: any) => {
        return getTimeDifference(new Date(from), new Date(to));
    };

    const [traineeNameToDisplay, plusNumberOfRemainingUsers] = traineeName.split(' ');

    const formattedDateAndTime = moment(scenarioInfo.from).format("DD/MM/YYYY, HH:mm");
    const [date, time] = formattedDateAndTime.split(",");

    let simulation = simulationName ? simulationName : "Simulation Name";
    let scenario = scenarioName ? scenarioName : "Scenario Name";

    const options = {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: true,
      };

    const { isLoading, data: d } = useQuery([getCustomerSettings], getCustomerSettings, options); 

    if (d?.customerName == "ra-mlrs") {
        simulation = "TCT - MLRS";
        scenario = "Scenario 01";
    }

    const cardLink = `/sessions/session-analysis?traineeName=${traineeName}&simulationName=${simulationName}&userId=${userId}&from=${from}&to=${to}&scenarioId=${simulationId}&simulationInstanceId=${id}&scenarioInstanceId=${scenarioInstanceId}&name=${scenarioName}&logoUrl=${logoUrl}`;
    const handleCardClick = (event: any) => {
        event.preventDefault();
        history.push(cardLink);
        if(sessionInfo.users.length != 0){
            localStorage.setItem('users',JSON.stringify(sessionInfo.users));
        }
    };

    return (
        <SessionCardWrapperDiv 
            onClick={(event) => handleCardClick(event)} 
            href={cardLink} 
            data-test-id={`session-${index+1}`}
        >
            <SecondaryWrapperDiv>
                <ContentWrapper>
                    <LogoWrapper>
                        <StyledImage width={isDesktopLargeMedia ? 64 : 48} src={logoUrl} />
                    </LogoWrapper>

                    <InfoWrapper>
                        <TitleWrapper>
                            <SimulationName>{simulation}</SimulationName>
                            <ScenarioAndBulletPointWrapper>
                                <BulletPoint>{"\u2022"}</BulletPoint>
                                <ScenarioName>{scenario}</ScenarioName>
                            </ScenarioAndBulletPointWrapper>
                        </TitleWrapper>

                        <UserInfoWrapper>
                            <UsersIcon24 fill="#999999" />
                                {traineeName.includes("+") ?
                                    <MultipleUsersDiv>
                                        <TruncatedStyledText>
                                            {traineeNameToDisplay}
                                        </TruncatedStyledText>
                                        <PlusSpan>{plusNumberOfRemainingUsers}</PlusSpan>
                                    </MultipleUsersDiv>
                                    :
                                    <TruncatedStyledText>{traineeName}</TruncatedStyledText>
                                }
                        </UserInfoWrapper>

                        <DateTimeAndDurationWrapper>
                            <DateWrapper>
                                <CalendarIcon24 fill="#999999" />
                                <DateText>
                                    {date}
                                </DateText>
                            </DateWrapper>

                            <TimeWrapper>
                                <Time fill="#999999" />
                                <TimeText>
                                    {time}
                                </TimeText>
                            </TimeWrapper>
                            {isDesktopLargeMedia && (
                                <DurationWrapper>
                                    <DurationText>{"\u2022"}</DurationText>
                                    <DurationText>{duration(from, to)}</DurationText>
                                </DurationWrapper>
                            )}
                        </DateTimeAndDurationWrapper>
                    </InfoWrapper>
                </ContentWrapper>

                <ArrowWrapper>
                    <CardArrowIcon48 />
                </ArrowWrapper>
            </SecondaryWrapperDiv>
        </SessionCardWrapperDiv>
    );
};

export default SessionCard;
