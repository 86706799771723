import React, { useState } from "react";
import {
    Container,
    Header,
    Title,
    HDivider,
    StatName,
    StatValue,
    StatsWrapper,
    IconAndSubtitleWrapper,
    SubTitle,
    TitleContainer  
} from "../../index.styles";
import ColumnChart from "../column-chart";
import { TopStats, Wrapper } from "./styles";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getProgressBarChart, getProgressStats, getSessionsStart } from "../../../../api/overview";
import { useProgressDatePreset } from "../../utils";
import { convertMillisecondsToTimespan } from "../../../../utils/functions";
import { BenefitsPropTypes } from "../../../../types/overview";
import EmptyState from "../../../../components/empty-state/empty-state";        
import FilterGroup from "../filter-group";
import { CalendarIcon24 } from "../../../../components/icons";
import moment from "moment";
import { ProgressFilters } from "../../../../enums/overview";
import Tooltip from "../../../../components/ui/tooltip/tooltip";
import {
    BarRepresentation,
    ProgressBarChart,
    ProgressTopStat,
    ProgressTopStatDatasets
} from "./progress-over-time.types";
import { EmptyStateType } from "../../../../components/empty-state/empty-state.types";
import ContentLoader from "../../../../components/content-loader/content-loader";

const ProgressOverTime: React.FC<BenefitsPropTypes> = ({ userId}) => {
    const [activeKey, setActiveKey] = useState<string>('last7days');
    const [selectedMonth, setSelectedMonth] = useState<number>();
    const [selectedYear, setSelectedYear] = useState<number>();
    const [date, setDate] = useState<moment.Moment | null>(moment());

    const {from, to, type, year} = useProgressDatePreset(activeKey, selectedMonth, selectedYear);

    const onChange = (key: string) => {
        setActiveKey(key);
    };
    
    const calendarHandler = (date: moment.Moment | null) => {
        setSelectedMonth(date?.month());
        setSelectedYear(date?.year());
        setDate(date);
    }

    const yearHandler = (date: moment.Moment | null) => {
        setSelectedYear(date?.year());
        setDate(date);
    }

    const { data: sessionsStartData, isError: isErrorSessionsStart } = useQuery(["getSessionsStart", userId],
        () =>
        getSessionsStart({ userId }),
        {
        suspense: false,
        refetchOnMount: false,
        useErrorBoundary: false,
        }
    );

    const { isFetching, data: statsData, isError: isErrorProgressStats } = useQuery<ProgressTopStat>
        (["getProgressStats",from, to, type, userId, selectedMonth, selectedYear],
        () =>
        getProgressStats({from, to, type, userId, year}),
        {
          suspense: false,
          refetchOnMount: false,
          useErrorBoundary: false,
        }
    );

    const { isFetching: loadingBarChart, data: chartData, isError: isErrorProgressBarChart} = useQuery<ProgressBarChart>(
        ["getProgressBarChart", from, to, type, userId, selectedMonth, selectedYear, year],
        () =>
        getProgressBarChart({from, to, type, userId, year}),
        {
            suspense: false,
            refetchOnMount: false,
            useErrorBoundary: false,
        }
    );

    const isLoading = isFetching || loadingBarChart;
    const isError = isErrorSessionsStart || isErrorProgressStats || isErrorProgressBarChart;

    const formatValue = (val: number, unit: string) => {
        if(unit === 'ms')
            return convertMillisecondsToTimespan(val);
        else
            return Math.trunc(val);
    }

    const getSubTitle = () => {
        switch(activeKey){
            case ProgressFilters.Last7Days:
            case ProgressFilters.Lifetime:
                return <FormattedMessage id={`overview:${activeKey}`} />
            case ProgressFilters.Month:
                return <span>{date?.format('MMMM')} {date?.year()}</span>
            case ProgressFilters.Year:  
                return <span>{date?.year()}</span>  
        }
    }

    const isMonthOrYear = activeKey === ProgressFilters.Month || activeKey === ProgressFilters.Year;

    const renderStats = () => {
        const datasets = statsData?.datasets ?? [];

        //removes that stat that has the prop "result": null
        const filteredStats: ProgressTopStatDatasets[] = datasets.filter((stat: ProgressTopStatDatasets) => {
            return stat.result !== null;
        });

        return filteredStats?.map((stat: ProgressTopStatDatasets, index: number) => {
            const testId = `progress-${stat.name}`.toLowerCase().replace(/\s+/g, '-');
            return (
                <React.Fragment key={stat.name}>
                    <StatsWrapper>
                        <StatName>{stat.name}</StatName>
                        <StatValue data-test-id={testId}>{formatValue(stat.result!, stat.unit)}</StatValue>
                    </StatsWrapper>
                    {filteredStats.length > 1 && index < (filteredStats.length - 1) && <HDivider />}
                </React.Fragment>
            )
        });
    };

    const renderProgressComponent = () => {
        if (isLoading) {
            return <ContentLoader height="16.1875rem" />;
        }

        if (isError) {
            return (
                <EmptyState
                    title="overview:sessions:error-state:title"
                    description="overview:sessions:error-state:description"
                    emptyStateType={EmptyStateType.Error}
                />
            );
        }
        
        if(chartData?.datasets.length === 0) {
            return (
                <EmptyState
                    title="overview:progress:empty-state:title"
                    description="overview:sessions:empty-state:description"
                    emptyStateType={EmptyStateType.Charts}
                />
            )}

        return(
            <Wrapper>
                <ColumnChart
                    data={chartData?.datasets}
                    filter={activeKey}
                    year={date?.year()}
                    barRepresentation={chartData?.barRepresentation || BarRepresentation.Score}
                />
                <TopStats>
                    {renderStats()}
                </TopStats>
            </Wrapper>
        )
    }

    return (
       <Container>
            <Header>
                <TitleContainer>
                    <Title>
                        <FormattedMessage id="overview:progress-over-time:title" />
                        {isMonthOrYear && <Tooltip text="overview:progress-over-time:tooltip" />}
                    </Title>

                    <FilterGroup 
                        activeKey={activeKey} 
                        onChange={onChange} 
                        date={date} 
                        onDateChange={calendarHandler} 
                        onYearChange={yearHandler}
                        sessionStart={sessionsStartData}
                    />
                </TitleContainer>

                <IconAndSubtitleWrapper>
                    <CalendarIcon24 fill="#999999" />
                    <SubTitle>
                        {getSubTitle()}
                    </SubTitle>
                </IconAndSubtitleWrapper>
            </Header>

            {renderProgressComponent()} 
              
        </Container>
    )
};

export default ProgressOverTime;
