import { useEffect, useState } from "react";
import useQueryParams from "../../../../../utils/use-query-params";
import { getCombinedCharts } from "../../../../../api/sessions";

export const usePolling = (poll: number) => {
    const params: any = useQueryParams();
    let userId = params.get("userId");
    let from: string = params.get("from");
    let to: string = params.get("to");
    let sessionId: string = params.get("sessionId");
    let simulationInstanceId: string = params.get("simulationInstanceId");
    let scenarioInstanceId: string = params.get("scenarioInstanceId");

    const [timelineData, setTimelineData] = useState<any>();

    useEffect(() => {
        async function getData() {
            const data = await getCombinedCharts({ from, to, userId, simulationInstanceId, scenarioInstanceId })
            setTimelineData(data);
        }
        getData();
    }, [poll]);

    return {
        timelineData
    };
};
