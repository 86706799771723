import { IconProps } from "../../../types/icon";

const ChevronDownSquareCornersIcon20 = ({
    width = 20,
    height = 20,
    fill = "#F2F2F2"
}: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path d="M5.89394 7.50016L5 8.38991L10 13.3335L15 8.38491L14.1061 7.50016L10 11.564L5.89394 7.50016Z" fill={fill} />
    </svg>
);

export default ChevronDownSquareCornersIcon20;
