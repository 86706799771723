import styled from "styled-components";
import Button from "../../../components/ui/button";
import { StatusType } from "../../../types/shared";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
`;

export const Description = styled.div`
  margin: 1.5rem 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.grey[50]};
`;

export const Form = styled.div`
  margin-top: 1rem;
  width: 100%;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubmitButton = styled(Button)`
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  margin-left: 1rem;

  & svg path {
    fill: ${({ theme }) => theme.colors.legacy.gray[400]};
  }
`;

export const Message = styled.p<{ status: StatusType }>`
  margin-top: 1rem;
  color: ${({ theme, status }) => theme.colors[status]};
`;

export const Warning = styled.p`
  margin-top: 0.4rem;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.primary.orange[500]};
`;
