import { useState } from 'react';
import styled from 'styled-components';
import { Title } from "../../session-detail.styles";
import { MultiSelectDropdown } from '../../../../components/filters/multi-select';
import TargetIcon from '../../../../components/icons/target';
import { CustomChartsDataProps } from '../../api/fetchCustomChartsData';

const Container = styled.div`
  background-color: #1a1a1a; /* Dark background */
  padding: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const StyledTable = styled.table`
  width: 100%;
  color: white;
  border-collapse: collapse;
  margin-top: 1.5rem;
`;

const StyledHeader = styled.th`
  background-color: #666666;
  padding: 10px 15px;
  width: 12.5%;
  height: 2.5rem;
  font-weight: 400;
`;

const StyledRow = styled.tr`
  width: 100%;
  background-color: #383838;

  &:nth-child(even) {
    background-color: #121212;
  }
`;

const StyledCell = styled.td`
   text-align: center;
  width: 6.2%;
  height: 3.75rem;
`;

const ScrollableTBody = styled.tbody`

  max-height: 300px;
  overflow-y: auto;
  width: 100%;
`;

const StyledHead = styled.thead`
  width: 100%;
  table-layout: fixed;
`;

export const FireMissions = ({customChartsData, isFetching}: CustomChartsDataProps) => {
  
  const dropdown = [];
  let log: any[] = [];
  let filteredList:any[] = [];

  for(let i = 0; i < customChartsData.length;i++){
    if(customChartsData[i].chartType === "ProgressBar" && customChartsData[i].code === "session-fire-mission-table"){
      for(let y = 0; y < customChartsData[i].datasets.length;y++){
        dropdown.push({FireMissionNumber:customChartsData[i].datasets[y].FireMissionNumber});
        log.push(customChartsData[i].datasets[y]);
      }
    }
  } 

  const [filteredLog, setFilteredLog] = useState(log);
  
    const uniqueMissions = Array.from(new Set(dropdown.map(mission => mission.FireMissionNumber)))
        .map(id => {
            return {
                label: `Mission ${id}`,
                id: id.toString()
            };
        });

        type Option = {
          id: string;
          label: string;
        }; 

        const handleSelectionChange = (selectedItems: Option[]) => {
          filteredList = [];
          for(let z = 0;z < selectedItems.length;z++ ){
            if(log.length === 1){
              filteredList.push(log[0]);
            }else{
              const filteredData = log.filter(x => x.FireMissionNumber === selectedItems[z].id.toString());
              filteredList.push(filteredData[0]);  
            }
          }

          setFilteredLog(filteredList);
        };

    return (
        <Container>
            <HeaderContainer>
                <Title>Fire Missions</Title>
                <MultiSelectDropdown
                  options={uniqueMissions}
                  title="Fire Missions"
                  style={{ width: '230px' }}
                  icon={<TargetIcon />}
                  handleOnClick={
                    () => {}
                  }
                  onSelectionChange={(selectedItems) => handleSelectionChange(selectedItems)}
                />
            </HeaderContainer>
            <div style={{ overflowX: 'auto',width:'100%' }}>
            <StyledTable style={{overflowX:'auto',whiteSpace:'nowrap'}}>
              <StyledHead>
                <tr>
                  <StyledHeader>FIRE MISSION</StyledHeader>
                  <StyledHeader>DISTANCE TRAVELLED</StyledHeader>
                  <StyledHeader>ROUNDS FIRED</StyledHeader>
                  <StyledHeader>PHASE 1 SCORE</StyledHeader>
                  <StyledHeader>PHASE 2 SCORE</StyledHeader>
                  <StyledHeader>PHASE 3 SCORE</StyledHeader>
                  <StyledHeader>PHASE 4 SCORE</StyledHeader>
                  <StyledHeader>OVERALL SCORE</StyledHeader>
                  <StyledHeader style={{textAlign:'left'}}>COMMENTS</StyledHeader>
                </tr>
              </StyledHead>
              <ScrollableTBody>
              {filteredLog.map(item => (
                <StyledRow key={item.FireMissionNumber}>
                  <StyledCell>{item.FireMissionNumber}</StyledCell>
                  <StyledCell>{item.DistanceTravelled}</StyledCell>
                  <StyledCell>{item.RoundsFired}</StyledCell>
                  <StyledCell>{item.Phase1Score}</StyledCell>
                  <StyledCell>{item.Phase2Score}</StyledCell>
                  <StyledCell>{item.Phase3Score}</StyledCell>
                  <StyledCell>{item.Phase4Score}</StyledCell>
                  <StyledCell>{item.OverallScore}</StyledCell>
                  <StyledCell>{item.Comments}</StyledCell>
                </StyledRow>    
                ))}                
              </ScrollableTBody>
            </StyledTable>
            </div>
        </Container>
        
        
    );
}
