import styled, { createGlobalStyle } from "styled-components";
import base from "../../../../themes/base/base";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const ButtonContainer = styled.ul`
    display: flex;
    margin: 0;

    li {
        ${base.typography.smallRegular};

        color: ${base.colors.neutral.grey[50]};
        border: 1px solid ${base.colors.neutral.grey[300]};
        background: ${base.colors.neutral.grey[900]};
        list-style-type: none;
        padding: 0.1875rem 0.75rem;

        &:first-of-type {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }

        &:last-of-type {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        &:not(:first-child) {
            border-left: 0;
        }

        &:focus {
            border: 1px solid ${base.colors.primary.orange[500]};
        }

        &.btn-active,
        &:hover {
            ${base.typography.smallMedium};

            background-color: ${base.colors.neutral.grey[600]};
            cursor: pointer;
        }
    }
`;

export const FilterContainer = styled.div`
    display: flex;
`;

export const StyledDatePicker = styled(DesktopDatePicker)`
    &.MuiFormControl-root.MuiTextField-root {
        margin-right: 0.625rem;
        cursor: pointer;
    }

    .MuiInputBase-root fieldset {
        display: none;
    }

    .MuiInputBase-root {
        display: flex;
        justify-content: center;
        width: 1.75rem;
        height: 1.75rem;
        padding: 2px;
        border: 1px solid ${base.colors.neutral.grey[300]};
        border-radius: 2px;

        &.Mui-focused {
            border: 1px solid ${base.colors.primary.orange[500]};
        }

        &:hover {
            background: ${base.colors.neutral.grey[600]};
        }

        .MuiOutlinedInput-input {
            display: none;
        }

        .MuiInputAdornment-root {
            margin-left: 0;

            button {
                padding: 0;
                margin-right: 0;

                svg {
                    fill: #f2f2f2;
                }
            }
        }
    }
`;

export const DatePickerStyles = createGlobalStyle`
    //global datepicker popup container
    .MuiPickersLayout-root {
        border-radius: 2px;
        border: 1px solid #999;
        background: ${base.colors.neutral.grey[800]};
        color: #f2f2f2;
        margin-top: 1rem; //space between container and calendar icon
        padding: 1rem;
        width: 19.5rem;
    }

    //Header & Date content
    .MuiPickersLayout-contentWrapper {
        width: 17.5rem;
    }

    //all Date rows container
    .MuiMonthCalendar-root, .MuiYearCalendar-root {
        padding: 0 !important;
        width: 17.5rem !important;
        row-gap: 0.46875rem;
        padding: 0.46875rem 0 !important;
        
    }
    .MuiPickersLayout-root .MuiDateCalendar-root {
        width: 17.5rem;
    }

    //each Date container
    .MuiPickersMonth-root, .MuiPickersYear-root {
        padding: 8px;
    }

    //calendar icon
    .MuiFormControl-root {
        display: flex;
        align-self: center;
    }

    //datepicker header
    .MuiPickersCalendarHeader-root {
        display: flex;
        margin: 0 !important;
        padding: 0 !important;

        .MuiPickersCalendarHeader-labelContainer {
            margin: 0 !important;
        }
    }
    
    //content inside wrapper (16px padding)
    .MuiPickersLayout-root .MuiDateCalendar-root {
        height: inherit;
    }
    

    //date (dates with data adopt white font color)
    .MuiDateCalendar-root .MuiPickersMonth-root button,
    .MuiDateCalendar-root .MuiPickersYear-root button {
        ${base.typography.smallRegular};

        height: 1.75rem;
        padding: 0.25rem 0.75rem;
        margin: 0;
    }

    //date with no data
    .MuiPickersMonth-root button.Mui-disabled {
        color: ${base.colors.neutral.grey[300]};
    }

    //selected date
    .MuiDateCalendar-root .MuiPickersMonth-root button.Mui-selected,
    .MuiDateCalendar-root .MuiPickersYear-root button.Mui-selected {
        background-color: ${base.colors.primary.orange[500]};
        color: ${base.colors.neutral.grey[900]};
    }

    //chevron to select the month/year
    .MuiPickersCalendarHeader-labelContainer button {
        color: ${base.colors.neutral.grey[50]};
    }

    //hover styles
    .MuiDateCalendar-root .MuiPickersMonth-root button:hover,
    .MuiDateCalendar-root .MuiPickersYear-root button:hover {
        background-color: ${base.colors.primary.orange[700]};
    }
`;
