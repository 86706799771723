import styled from "styled-components";
import base from "../../../themes/base/base";
import {
    PrimaryButton,
    SecondaryButton,
    TertiaryButton
} from "../../../pages/shared/shared.styles";

export const Container = styled.div`
  background-color: ${base.colors.neutral.grey[900]};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentAndBackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45.5rem;
  height: 25.59125rem;
  background-image:url("/assets/error-page/ErrorLines.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${base.colors.neutral.grey[800]};
  border-radius: 8px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-family: "Roboto";
  ${base.typography.extraLarge2Regular};
  color: ${base.colors.neutral.grey[50]};
  padding-bottom: 0.75rem;
  text-align: center;
`;

export const Description = styled.span`
  font-family: "Roboto";
  ${base.typography.baseRegular};
  color: ${base.colors.neutral.grey[50]};
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 3rem;
  width: 19rem;
`;

export const RefreshButton = styled(PrimaryButton)``;

export const OverviewButton = styled(SecondaryButton)``;

export const LogoutButton = styled(TertiaryButton)``;
