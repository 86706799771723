import React from "react";
import { useModal } from "../../../../../../context/modal/modal";
import {
    ButtonsWrapper,
    ErrorDescription,
    ModalContentWrapperDiv,
    NotAddedUser,
    NotAddedUsersWrapper,
    StyledCancelButton,
    StyledConfirmButton,
    Title
} from "../../modal-contents.styles";
import { FormattedMessage } from "react-intl";
import AddUserModalContent, {
    AddMultipleUsersSuccessfulAPIResponse,
    AddUserErrorAPIResponse
} from "../add-user/add-user-modal-content";
import { AddUsersEmailErrorAPIResponse } from "../../../../../../pages/users/components/options-bar/options-bar.types";

interface AddUserNotSuccessfulModalContentProps {
    title: string;
    description?: string;
    addSingleUserErrorAPIResponse?: AddUserErrorAPIResponse[];
    addMultipleUsersSuccessfulAPIResponse?: AddMultipleUsersSuccessfulAPIResponse;
    setIsNewUserAdded?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUserNotSuccessfulModalContent = ({
    title,
    description,
    addSingleUserErrorAPIResponse,
    addMultipleUsersSuccessfulAPIResponse,
    setIsNewUserAdded
}: AddUserNotSuccessfulModalContentProps) => {
    const { openModal, closeModal } = useModal();
    const isSingleUserNotAddedBecauseOfExistingEmail = addSingleUserErrorAPIResponse?.[0].errorCode === AddUsersEmailErrorAPIResponse.EmailAlreadyExists;
    const notAddedSingleUser = addSingleUserErrorAPIResponse;

    const areMultipleUsersNotAddedBecauseOfExistingEmail = addMultipleUsersSuccessfulAPIResponse && addMultipleUsersSuccessfulAPIResponse.notCreatedUsers.length > 0;
    const notAddedMultipleUsers = addMultipleUsersSuccessfulAPIResponse?.notCreatedUsers;

    const handleAddMoreUsersClick = () => {
        openModal(
            <AddUserModalContent
                title="users:users-table:modal-contents:add-user:title"
                description="users:users-table:modal-contents:add-user:description"
                setShouldUpdateUsersList={setIsNewUserAdded}
            />
        );
    };

    const handleAddUsersConfirmationModalClose = () => {
        closeModal();
    };

    return (
        <ModalContentWrapperDiv>
            <Title>
                <FormattedMessage id={title} />
            </Title>
            <ErrorDescription>
                <FormattedMessage id={description} />
            </ErrorDescription>

            {isSingleUserNotAddedBecauseOfExistingEmail &&
                <NotAddedUsersWrapper>
                    <NotAddedUser>{notAddedSingleUser?.[0].attemptedValue}</NotAddedUser>
                </NotAddedUsersWrapper>
            }

            {areMultipleUsersNotAddedBecauseOfExistingEmail &&
                <NotAddedUsersWrapper>
                    {notAddedMultipleUsers?.map((user, index) => {
                        return (
                            <React.Fragment key={`${user.emailAddress}${index}`}>
                                <NotAddedUser>{user.emailAddress}</NotAddedUser>
                                {index < notAddedMultipleUsers?.length - 1 && <NotAddedUser>,&nbsp;</NotAddedUser>}
                            </React.Fragment>
                        );
                    })}
                </NotAddedUsersWrapper>
            }

            <ButtonsWrapper>
                <StyledConfirmButton
                    onClick={handleAddMoreUsersClick}
                    fullWidth
                >
                    <FormattedMessage id="users:users-table:modal-contents:add-user:confirmation-modal:primary-button" />
                </StyledConfirmButton>
                <StyledCancelButton
                    onClick={handleAddUsersConfirmationModalClose}
                    fullWidth
                >
                    <FormattedMessage id="users:users-table:modal-contents:confirmation-modal:primary-button" />
                </StyledCancelButton>
            </ButtonsWrapper>

        </ModalContentWrapperDiv>
    );
};

export default AddUserNotSuccessfulModalContent;
