import styled from "styled-components";
import base from "../../../../../themes/base/base";

export const InstrumentsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const InstrumentsInnerContainer = styled.div`
  display: flex;
  width: 47.9375rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const NoDataContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 69%;
  text-align: center;
  color: #53555A;
  z-index: 1;
`;

export const NoDataText = styled.div`
  ${base.typography.extraLarge2Bold};
  
  font-weight: 400;
  
  & + & {
    ${base.typography.extraLargeMedium};

    font-weight: 400;
    line-height: 1.6875rem;
    letter-spacing: 0.01125rem;
  }
`;

interface InstrumentWrapperProps {
  isDisabled?: boolean;
  noData?: boolean;
}

export const InstrumentWrapper = styled.div<InstrumentWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 12rem !important;
    height: 12rem !important;
  }

  ${({ isDisabled, noData }) => `
    ${isDisabled ? `
        opacity: 0.3;
        filter: grayscale(100%);
      ` : ''}
    ${noData ? `
        svg {
          filter: brightness(0%);
        }
      ` : ''}
  `}
`;
