import React, { useCallback, useState } from "react";
import {
    DropdownMenuWrapper,
    MenuItem,
    OptionsWrapper,
    Placeholder,
    PlaceholderWrapper,
    SelectWrapperDiv,
    Error,
    SelectedOption
} from "./select.styles"
import { useDetectClickOutside } from "react-detect-click-outside";
import { ChevronDownSquareCornersIcon24 } from "../../../../../components/icons";
import { useIntl } from "react-intl";
import { BottomSeparator, Label } from "../../survey.styles";
import { SelectProps } from "./select.types";
import { QuestionOption } from "../../survey.types";

const Select = ({
    registeredField,
    label,
    labelMarginBottom,
    labelHeight,
    selectPlaceholder,
    options,
    fieldError,
    formattedRequiredMessage,
    register,
    setValue,
    clearErrors,
}: SelectProps) => {
    const intl = useIntl();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState<number | null>(null);
    const [selectedOptionValue, setSelectedOptionValue] = useState<string | null>(null);

    const onCloseMenu = useCallback(() => {
        if (!isOpen) return;

        setIsOpen(false);
    }, [isOpen]);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: onCloseMenu
    });

    const handleOpenMenu = () => {
        setIsOpen(!isOpen);
        clearErrors(registeredField); //clear error state when menu is opened
    };

    const handleSelectedOption = (option: QuestionOption, index: number) => {
        setSelectedOptionValue(option.value);
        setActiveDropdownIndex(index);
        setIsOpen(false);

        setValue(registeredField, option.code);
        clearErrors(registeredField);
    };

    return (
        <SelectWrapperDiv>
            <Label marginBottom={labelMarginBottom} height={labelHeight}>{label}</Label>
            <DropdownMenuWrapper ref={clickOutsideRef}>
                <PlaceholderWrapper
                    onClick={handleOpenMenu}
                    className={fieldError ? "error" : ""}
                    isOpen={isOpen}
                    {...register(registeredField, {
                        required: intl.formatMessage({
                            id: formattedRequiredMessage
                        })
                    })}
                >
                    {selectedOptionValue ? (
                        <SelectedOption>{selectedOptionValue}</SelectedOption>
                    ) : (
                        <Placeholder>{selectPlaceholder}</Placeholder>
                    )}
                    <ChevronDownSquareCornersIcon24 />
                </PlaceholderWrapper>
                {isOpen && (
                    <OptionsWrapper className={fieldError ? "error" : ""}>
                        {options.map((option: QuestionOption, index) => {
                            return (
                                <MenuItem
                                    key={option.code}
                                    id={option.value}
                                    isSelected={activeDropdownIndex === index}
                                    onClick={() =>
                                        handleSelectedOption(option, index)
                                    }
                                >
                                    {option.value}
                                </MenuItem>
                            );
                        })}
                    </OptionsWrapper>
                )}
            </DropdownMenuWrapper>
            {fieldError && <Error>{fieldError.message}</Error>}
            {!fieldError && <BottomSeparator />}
        </SelectWrapperDiv>
    );
};

export default Select;
