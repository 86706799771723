import { ProgressBar } from "./score-chart";
import { Section } from "./score-chart.styles";
import { Title } from "../../session-detail.styles";
import Tooltip from "../../../../components/ui/tooltip/tooltip";
import { CustomChartsDataProps } from "../../api/fetchCustomChartsData";

export type Score = {
    scoreTitle: string,
    score: number,
    description: string,
    position: number
}

export const OverallScore = ({customChartsData, isFetching}: CustomChartsDataProps) => {

    let scores = customChartsData.filter((p: any) => p.chartType === "ProgressBar" && p.code == "session-score")
    [0].datasets[0];  
    
    if(scores == undefined || scores === null){
        scores = {
            overallScore:0,
            score1:0,
            score2:0,
            score3:0,
            score4:0,
            overallScoreDescription:"Average Fire Mission Score",
            overallScoreToolTip:"The Average Fire Mission Score shows the average figure of the combined Overall Scores.  This is calculated by adding the Overall Score for each individual Fire Mission completed and dividing this by the number of Fire Missions completed in the training session.",
            partialScoreDescription:"Average Phase Score",
            partialScoreTooltip:"The Average Phase Score shows the average figure for each separate Phase Score.  For example, the Phase 1 average figure is calculated by adding all Phase 1 scores and dividing this by the number of Phase 1 stages completed in the training session."
        }
    }
    
    const phaseScores = [
        scores.score1,
        scores.score2,
        scores.score3,
        scores.score4,
    ];
    
    if (isFetching) return <div>Loading...</div>;

    return(
        <Section.Container>
            <>
                {scores && <Section.FiringScore>
                    <Title>
                        Overall Score
                    </Title>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <ProgressBar width={293} valueEnd={Math.round(scores.overallScore)} scoreFontSize="6rem" totalScoreFontSize="3rem"/>
                    </div>

                    <Section.Description> 
                        {scores.overallScoreDescription}
                        <Tooltip text={scores.overallScoreToolTip} />
                    </Section.Description>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', boxSizing: 'border-box' }}>
                   
                    {Array.from({ length: 4 }).map((_, index) => (
                            <div key={index}>
                                <ProgressBar width={82} valueEnd={Math.round(phaseScores[index])} scoreFontSize="2rem" totalScoreFontSize="1rem"/>
                                <div style={{ marginTop: '1.5rem', color: '#999999', textAlign: 'center', lineHeight: '1.675rem' }}>
                                    Phase {index + 1}
                                </div>
                            </div>
                        ))}
                        
                    </div>
                    
                    <Section.Description>
                        {scores.partialScoreDescription}
                        <Tooltip text={scores.partialScoreTooltip} />
                    </Section.Description>

                </Section.FiringScore>}
            </>
        </Section.Container>
    )
 }
