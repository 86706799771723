export enum SurveyQuestionType {
    Text = "Text",
    Select = "Select",
    Radio = "Radio",
    NumericSlider = "NumericSlider",
    PercentageSlider = "PercentageSlider",
    Rate = "Rate",
    Numeric = "Numeric",
    Boolean = "Boolean"
};
