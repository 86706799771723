import { FormattedMessage } from "react-intl";
import {
    DeleteAccountDescription,
    ModalContentWrapperDiv,
    StyledEmail
} from "../modal-contents.styles";

interface Props {
    title: string;
    description?: string;
}

const DeleteAccountAdminModal = ({
    title
}: Props) => {
    return (
        <ModalContentWrapperDiv>
            <DeleteAccountDescription>
                <FormattedMessage id={title} values={{lineBreak: <br />}}/>
                <StyledEmail href="mailto:support@vrai.ie">
                    <FormattedMessage id="account-delete:modal-contents:admin:email"/>
                </StyledEmail>
            </DeleteAccountDescription>
        </ModalContentWrapperDiv>
    );
};

export default DeleteAccountAdminModal;
