import useQueryParams from "../../../utils/use-query-params";
import { getCustomCharts } from "../../../api/sessions";
import { useQuery } from "react-query";

export interface CustomChartsDataProps {
    customChartsData: any,
    isFetching: boolean
}

export const fetchCustomChartsData = () => {
    const params: any = useQueryParams();
    let userId = params.get("userId");
    let from: string = params.get("from");
    let to: string = params.get("to");
    let simulationInstanceId: string = params.get("simulationInstanceId");
    let scenarioInstanceId: string = params.get("scenarioInstanceId");

    const {
        isFetching,
        data: customChartsData,
    } = useQuery(
        ["getCustomCharts", from, to, userId, simulationInstanceId, scenarioInstanceId],
        () => getCustomCharts({ from, to, userId, simulationInstanceId, scenarioInstanceId }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true
        }
    );

    return {
        customChartsData,
        isFetching
    };
};
