import useQueryParams from "../../../utils/use-query-params";
import { getCombinedCharts } from "../../../api/sessions";
import { useQuery } from "react-query";

export interface CombinedChartsDataProps {
    combinedChartsData: any,
    isFetching: boolean
}

export const fetchCombinedChartsData = () => {
    const params: any = useQueryParams();
    let userId = params.get("userId");
    let from: string = params.get("from");
    let to: string = params.get("to");
    let simulationInstanceId: string = params.get("simulationInstanceId");
    let scenarioInstanceId: string = params.get("scenarioInstanceId");

    const {
        isFetching,
        data: combinedChartsData,
    } = useQuery(
        ["getCombinedCharts", from, to, userId, simulationInstanceId, scenarioInstanceId],
        () => getCombinedCharts({ from, to, userId, simulationInstanceId, scenarioInstanceId }),
        {
            suspense: false,
            refetchOnMount: true,
            useErrorBoundary: false,
            enabled: true
        }
    );

    return {
        combinedChartsData,
        isFetching
    };
};
