import React, { RefObject, useEffect, useRef } from "react";
import { useModal } from "../../../context/modal/modal";
import {
    CloseContainer,
    ModalBackdrop,
    ModalContent,
    CloseIconContainer,
    Content
} from "./modal-with-context.styles";
import { CrossIcon32 } from "../../icons";
import { VRIntlProviderComponent } from "../../providers/intl-provider";

const localeFn = (target: string) =>
    import(`./locale/${target.toLowerCase()}.json`);

interface ModalProps {
    focusRef?: RefObject<HTMLInputElement>;
};
interface ModalWithContextProps {
    focusRef?: RefObject<HTMLInputElement>;
};

const Modal = ({ focusRef }: ModalProps) => {
    const {
        isModalOpen,
        closeModal,
        modalContent,
        width,
        height,
        minWidth,
        minHeight
    } = useModal();

    const modalRef = useRef<HTMLDivElement>(null);

    const handleClickOutsideModal = (event: MouseEvent) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            closeModal();
        }
    };

    const handleEscKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            document.addEventListener("mousedown", handleClickOutsideModal);
            document.addEventListener("keydown", handleEscKeyPress);
            if (focusRef && focusRef.current) {
                focusRef.current.focus();
            }
        } else {
            document.removeEventListener("mousedown", handleClickOutsideModal);
            document.removeEventListener("keydown", handleEscKeyPress);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideModal);
            document.removeEventListener("keydown", handleEscKeyPress);
        };
    }, [isModalOpen]);

    if (!isModalOpen) return null;

    return (
        <ModalBackdrop>
            <ModalContent
                ref={modalRef}
                width={width}
                height={height}
                minWidth={minWidth}
                minHeight={minHeight}
            >
                <CloseContainer>
                    <CloseIconContainer onClick={closeModal}>
                        <CrossIcon32 />
                    </CloseIconContainer>
                </CloseContainer>
                <Content>{modalContent}</Content>
            </ModalContent>
        </ModalBackdrop>
    );
};

const ModalWithContext = ({ focusRef }: ModalWithContextProps) => {
    return (
        <VRIntlProviderComponent
            localeFn={localeFn}
            id="modal-with-context"
            fallback={null}
        >
            <Modal focusRef={focusRef} />
        </VRIntlProviderComponent>
    );
};

export default ModalWithContext;
