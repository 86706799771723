import { IconProps } from "../../../types/icon";

const CardArrowIcon64 = ({
    fill = "#999999",
    width = 64,
    height = 64
}: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 64 64"
        fill="none"
    >
        <path
            d="M23 45.1394L25.898 48L42 32L25.8817 16L23 18.8606L36.2365 32L23 45.1394Z"
            fill={fill}
        />
    </svg>
);

export default CardArrowIcon64;
