import React, { useEffect, useMemo, useCallback } from 'react';
import {
  Airspeed,
  Altimeter,
  AttitudeIndicator,
  HeadingIndicator,
  Variometer,
} from "react-typescript-flight-indicators";
import {
  InstrumentsContainer,
  InstrumentsInnerContainer,
  InstrumentWrapper,
  NoDataContainer,
  NoDataText
} from "./styles";
import { AirspeedBackgroundSvg } from './airspeed-svg';
import { AltitudeBackgroundSvg } from './altitude-svg';
import { FormattedMessage } from 'react-intl';
import ContentLoader from '../../../../../components/content-loader/content-loader';

interface InstrumentsProps {
  airSpeed: number;
  bank: number;
  pitch: number;
  altitude: number;
  heading: number;
  verticalSpeed: number;
  noData: boolean;
  isLoading: boolean;
}

const colorMap = {
  '#232323': '#161215',
  '#353535': '#53555A',
  '#B2B2B2': '#999999',
  '#FFF': '#F2F2F2',
  '#FFFFFF': '#F2F2F2',
  '#FF2A00': '#EE7127',
  '#FF7F00': '#EE7127',
  '#3D2618': '#38250D',
  '#503723': '#543713',
  '#558EBB': '#2A84B0',
  '#9CCBE5': '#B3DFEC',
  '#FF0000': '#C13D40',
  '#007511': '#81842C',
  '#F9FF00': '#F39E37'
};

const replaceSvgColors = (colorMap: { [key: string]: string }) => {
  const svgs = document.querySelectorAll('svg');

  svgs.forEach(svg => {
    const traverseAndUpdateColors = (element: Element) => {
      const fill = element.getAttribute('fill');
      const stroke = element.getAttribute('stroke');

      if (fill && colorMap[fill]) {
        element.setAttribute('fill', colorMap[fill]);
      }

      if (stroke && colorMap[stroke]) {
        element.setAttribute('stroke', colorMap[stroke]);
      }

      Array.from(element.children).forEach(child => traverseAndUpdateColors(child));
    };

    traverseAndUpdateColors(svg);
  });
};

const replaceTargetSvg = (targets: { selector: string; svgContent: string }[]) => {
  targets.forEach(target => {
    const element = document.querySelector(target.selector);
    if (element) {
      element.innerHTML = target.svgContent;
    }
  });
};

const toDegrees = (radians: number) => radians * (180 / Math.PI);

const toUnits = {
  'speed': (speed: number) => speed / 5,
  'roll': (roll: number) => toDegrees(roll),
  'pitch': (pitch: number) => toDegrees(pitch),
  'heading': (heading: number) => toDegrees(heading),
  'verticalSpeed': (verticalSpeed: number) => verticalSpeed * 60
}

const MemoizedAirspeed = React.memo(Airspeed);
const MemoizedAltimeter = React.memo(Altimeter);
const MemoizedAttitude = React.memo(AttitudeIndicator);
const MemoizedHeading = React.memo(HeadingIndicator);
const MemoizedVariometer = React.memo(Variometer);

const Instruments: React.FC<InstrumentsProps> = ({
  airSpeed,
  bank,
  pitch,
  altitude,
  heading,
  verticalSpeed,
  noData,
  isLoading
}) => {
  useEffect(() => {
    replaceTargetSvg([
      { selector: '.airspeed > div > svg', svgContent: AirspeedBackgroundSvg },
      { selector: '.altimeter > div > svg', svgContent: AltitudeBackgroundSvg }
    ]);
    replaceSvgColors(colorMap);
  }, [isLoading]);

  const isDisabled = useCallback((value: any): boolean => {
    return noData || !(typeof value === 'number' && !isNaN(value));
  }, [noData]);

  const instrumentConfig = useMemo(() => [
    {
      key: 'Airspeed',
      isDisabled: isDisabled(airSpeed),
      component: MemoizedAirspeed,
      props: { speed: toUnits.speed(airSpeed), showBox: false },
    },
    {
      key: 'Attitude',
      isDisabled: isDisabled(bank) && isDisabled(pitch),
      component: MemoizedAttitude,
      props: { roll: toUnits.roll(bank), pitch: toUnits.pitch(pitch), showBox: false },
    },
    {
      key: 'Altimeter',
      isDisabled: isDisabled(altitude),
      component: MemoizedAltimeter,
      props: { altitude: altitude, showBox: false },
    },
    {
      key: 'Heading',
      isDisabled: isDisabled(heading),
      component: MemoizedHeading,
      props: { heading: toUnits.heading(heading), showBox: false },
    },
    {
      key: 'Variometer',
      isDisabled: isDisabled(verticalSpeed),
      component: MemoizedVariometer,
      props: { vario: toUnits.verticalSpeed(verticalSpeed), showBox: false },
    },
  ], [airSpeed, bank, pitch, altitude, heading, verticalSpeed, isDisabled]);

  return (
    <InstrumentsContainer>
      {noData && !isLoading && (
        <NoDataContainer>
          <div>
            <NoDataText>
              <FormattedMessage id={"session-detail:playback-unavailable-title"} />
            </NoDataText>
            <NoDataText>
              <FormattedMessage id={"session-detail:playback-unavailable-description"} />
            </NoDataText>
          </div>
        </NoDataContainer>
      )}

      {isLoading ? (
        <ContentLoader height="24rem" />
      ) : (
        <InstrumentsInnerContainer>
          {instrumentConfig.map((instrument) => (
            <InstrumentWrapper key={instrument.key} isDisabled={instrument.isDisabled} noData={noData} className={instrument.key.toLowerCase()}>
              {React.createElement(instrument.component, instrument.props)}
            </InstrumentWrapper>
          ))}
        </InstrumentsInnerContainer>
      )}
    </InstrumentsContainer>
  );
};

export default Instruments;
