import React, { useState } from 'react';
import { Section } from "./styles";
import { Title } from "../../session-detail.styles";
import ReactApexChart from 'react-apexcharts';
import base from '../../../../themes/base/base';
import { Input, Label, RadioWrapper } from "../timeline-chart-with-firing/styles";
import { StyledRadioButton } from "../../../../components/ui/styled-radio";
import { Option, MultiSelectDropdown } from '../../../../components/filters/multi-select';
import styled from 'styled-components';
import TargetIcon from '../../../../components/icons/target';
import { useQuery } from 'react-query';
import { getCustomCharts } from '../../../../api/sessions';

// Define an interface for the new data structure
interface ResponseTimeData {
  FireMissionNumber: string;
  Phase1DurationSeconds: number;
  Phase2DurationSeconds: number;
  Phase3DurationSeconds: number;
  Phase4DurationSeconds: number;
}

interface DataType {
  id: number;
  name: string;
  value: any;
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleFlex = styled(Title)`
  text-align: center;
  flex: 1;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

interface ChartType {
  label: string;
  color: string;
}

interface StackedBarChartProps {
  series: any[];
  options: any; 
}

const StackedBarChart = ({ series, options }: StackedBarChartProps) => (
  <div style={{ width: '100%', height: 'auto' }}>
    <ReactApexChart options={options} series={series} type="bar" />
  </div>
);

const chartTypes: Record<string, ChartType> = {
  phase1: { label: 'Phase 1', color: base.colors.primary.orange[100] },
  phase2: { label: 'Phase 2', color: base.colors.primary.orange[300] },
  phase3: { label: 'Phase 3', color: base.colors.primary.orange[500] },
  phase4: { label: 'Phase 4', color: base.colors.primary.blue[500] },
  annotations: { label: 'Annotations', color: base.colors.neutral.grey[500] }
};

const chartStateByType: any = {
  phase1: true,
  phase2: true,
  phase3: true,
  phase4: true,
  annotations: true
};

const changeHandler = (chartType: string) => {
  chartStateByType[chartType] = !chartStateByType[chartType];
};

// Define an interface for the error object
interface ErrorType {
  message: string;
}

interface ResponseItem {
  OperationId: string;
  FireMissionNumber: string;
  Phase1DurationSeconds: number;
  Phase2DurationSeconds: number;
  Phase3DurationSeconds: number;
  Phase4DurationSeconds: number;
}

interface ResponseData {
  code: string;
  datasets: any[];
}

export const ResponseTime = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const simulationInstanceId = queryParams.get('simulationInstanceId') || '';
  const scenarioInstanceId = queryParams.get('scenarioInstanceId') || '';
  const userId = queryParams.get('userId') || '';
  const from = queryParams.get('from') || '';
  const to = queryParams.get('to') || '';
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  
  const { data, isLoading, error } = useQuery<ResponseTimeData[], ErrorType>(
    ['getCustomCharts', simulationInstanceId, scenarioInstanceId, userId, from, to], 
    async () => {
      const response = await getCustomCharts({
        simulationInstanceId,
        scenarioInstanceId,
        userId,
        from,
        to
      });
      const sessionResponseTimeData = response.find((d: ResponseData) => d.code === "session-response-time");
      return sessionResponseTimeData?.datasets.map((item: any) => ({
        FireMissionNumber: item.FireMissionNumber,
        Phase1DurationSeconds: item.Phase1DurationSeconds || Math.random() * 100,
        Phase2DurationSeconds: item.Phase2DurationSeconds || Math.random() * 100,
        Phase3DurationSeconds: item.Phase3DurationSeconds || Math.random() * 100,
        Phase4DurationSeconds: item.Phase4DurationSeconds || Math.random() * 100,
      })) || [];
    }
  );

  const options = {
    chart: {
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false 
      },
      animations: {
        enabled: false,
      },
      type: 'bar' as const,
      stacked: true,
      //height: data.length * 50,
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '40%',
      },
    },
    stroke: {
      width: 0,
      colors: ['#fff']
    },
    grid: {
      strokeDashArray: 8,
      borderColor: "#666666"
    },
    xaxis: {
      categories: data?.map((item: ResponseTimeData) => item.FireMissionNumber),
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: '#666666'
      },
      labels: {
        style: {
          colors: base.colors.neutral.grey[50],
          fontSize: '1rem'
        },
        show: false
      },
      tooltip: {
        enabled: true
      }
    },
    yaxis: {
      title: {
        text: undefined
      },
      labels: {
        style: {
          colors: base.colors.neutral.grey[50],
          fontSize: '1rem'
        }
      }
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
          return val;
        }
      }
    },
    fill: {
      colors: [
        base.colors.primary.orange[100],
        base.colors.primary.orange[300],
        base.colors.primary.orange[500],
        base.colors.primary.blue[500],
      ]
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false
    }
  };

  // Define the series using the fetched data and filter based on selected options
  const series = data ? Object.entries(chartTypes).map(([key, { label, color }]) => ({
    name: label,
    data: data.filter(item => selectedOptions.some(option => option.id === item.FireMissionNumber))
              .map(item => item[key + 'DurationSeconds' as keyof ResponseTimeData] as number),
    color: color
  })) : [];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  const handleDropdownClick = (name: string | null) => {
    setIsDropdownOpen(name === "multi-select");
  };

  const handleSelectionChange = (selected: Option[]) => {
    setSelectedOptions(selected);
  };

  const dropdownOptions = data?.map((item: ResponseTimeData) => ({ id: item.FireMissionNumber, label: item.FireMissionNumber })) || [];

  return (
    <Section.Container>
      <Section.LandingScore>
        <TitleContainer>
          <TitleFlex>
            Response Time
          </TitleFlex>
          <DropdownContainer>
            <MultiSelectDropdown
              handleOnClick={handleDropdownClick}
              onSelectionChange={handleSelectionChange}
              options={dropdownOptions}
              style={{ width: '230px' }}
              title="Fire Missions"
              icon={<TargetIcon />}
            />
          </DropdownContainer>
        </TitleContainer>
        <RadioWrapper>
          {Object.entries(chartTypes).map(([chartType, chartProps]) => (
            <Label key={chartType} htmlFor={chartType} className={chartStateByType[chartType] ? '' : 'unchecked'}>
              <Input
                type="checkbox"
                defaultChecked
                id={chartType}
                onChange={() => changeHandler(chartType)}
              />
              <StyledRadioButton
                selected={!!chartStateByType[chartType]}
                color={chartProps?.color}
              ></StyledRadioButton>
              {chartProps?.label}
            </Label>
          ))}
        </RadioWrapper>
        <div>
          <StackedBarChart series={series} options={options} />
        </div>
      </Section.LandingScore>
    </Section.Container>
  );
}
