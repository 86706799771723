import { FormattedMessage } from "react-intl";
import {
    EmptyStateContent,
    EmptyStateSecondaryWrapperDiv,
    EmptyStateWrapperDiv,
    Description,
    Title,
    TitleAndDescriptionWrapper
} from "./empty-state.styles";
import { EmptyStateProps, EmptyStateType } from "./empty-state.types";
import { EmptyChartsStateIcon, EmptySessionsStateIcon, ErrorStateIcon } from "../icons";

const EmptyState = ({
    title,
    description,
    emptyStateType,
    svgWidth,
    svgHeight
}: EmptyStateProps) => {

    const renderStateSVG = (emptyStateType: EmptyStateType) => {
        switch (emptyStateType) {
            case EmptyStateType.Sessions:
                return <EmptySessionsStateIcon width={svgWidth} height={svgHeight} />;
            case EmptyStateType.Charts:
                return <EmptyChartsStateIcon width={svgWidth} height={svgHeight} />;
            case EmptyStateType.Error:
                return <ErrorStateIcon width={svgWidth} height={svgHeight} />
        }
    };

    return (
        <EmptyStateWrapperDiv>
            <EmptyStateSecondaryWrapperDiv>
                <EmptyStateContent>

                    {renderStateSVG(emptyStateType)}

                    <TitleAndDescriptionWrapper>
                        <Title>
                            <FormattedMessage id={title} />
                        </Title>
                        <Description>
                            <FormattedMessage id={description} />
                        </Description>
                    </TitleAndDescriptionWrapper>
                    
                </EmptyStateContent>
            </EmptyStateSecondaryWrapperDiv>
        </EmptyStateWrapperDiv>
    );
};

export default EmptyState;
