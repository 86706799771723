import styled, { css } from "styled-components";
import base from "../../../../themes/base/base";

export const SessionsWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.0625rem;
    width: 100%;
`;

export const HeaderWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.grey[800]};
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 1.5rem 1.5rem 0.75rem;
`;

export const BottomWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.grey[800]};
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0.75rem 0;
`;

export const ViewAllButton = styled.button`
    ${base.typography.largeMedium};

    padding: 0;
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary.orange[500]};
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    border-radius: 3.125rem;

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.grey[700]};
    }

    &:disabled {
        color: ${({ theme }) => theme.colors.neutral.grey[500]};
        cursor: not-allowed;
    }
`;

export const ButtonContentWrapper = styled.div`
    display: flex;
    gap: 0.25rem;
`;

//empty session card
export const EmptySessionCard = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.grey[800]};
    height: 100%;
`;
