import { IconProps } from "../../../types/icon";

const ErrorState = ({
    width = 128,
    height = 128
}: IconProps) => (
    <div data-test-id="error_state">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 128 128"
            fill="none"
        >
            <g clipPath="url(#clip0_4129_25747)">
                <mask id="mask0_4129_25747" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="128">
                    <path d="M128 0H0V128H128V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_4129_25747)">
                    <path
                        d="M74.0662 121.135L73.4763 117.708H73.4537L73.1812 116.165C70.2037 116.71 67.1113 116.97 63.9963 116.97C57.6313 116.97 51.5288 115.835 45.8763 113.77L45.3338 115.245L44.7212 116.948L44.1437 118.513L42.7287 122.415L42.0938 124.14C48.9212 126.648 56.2937 127.998 63.9837 127.998C67.7662 127.998 71.4812 127.668 75.0937 127.045L74.7662 125.23L74.0637 121.135H74.0662Z"
                        fill="#121212"
                    />
                    <path
                        d="M96.3177 110.095L95.2752 108.608L94.3802 107.338C93.1227 108.233 91.8102 109.063 90.4727 109.858L91.2652 111.208L92.1702 112.785L92.6902 113.67L93.2227 114.6L94.0827 116.098L95.0802 117.835L95.9977 119.423C97.6177 118.47 99.1802 117.47 100.71 116.405L97.2777 111.47L96.3252 110.108L96.3127 110.095H96.3177Z"
                        fill="#121212"
                    />
                    <path
                        d="M127.753 58.2875L121.795 58.81L120.435 58.935L120.13 58.9575L118.33 59.1175V59.14L116.768 59.265C116.893 60.7975 116.96 62.34 116.96 63.8825C116.96 65.425 116.893 67.025 116.768 68.58L122.18 69.17V69.1475L123.9 69.33V69.285L125.883 69.5V69.4775L127.718 69.67C127.888 67.7525 127.99 65.8225 127.99 63.8725C127.99 61.9225 127.9 60.1275 127.74 58.29H127.75L127.753 58.2875Z"
                        fill="#121212"
                    />
                    <path
                        d="M121.126 73.9675L119.484 73.6825L117.706 73.365L116.166 73.0925C114.806 80.875 111.739 88.08 107.379 94.31L108.669 95.205L111.861 97.4525L113.266 98.44V98.43L114.909 99.5875L116.391 100.643C121.679 93.1075 125.384 84.405 127.024 75.0225L125.211 74.6925L121.124 73.9675H121.126Z"
                        fill="#121212"
                    />
                    <path
                        d="M108.413 101.223L107.133 100.155L105.751 98.9975L104.551 97.9875C103.566 99.18 102.523 100.303 101.426 101.403L105.288 105.273L106.511 106.498L107.916 107.905L109.218 109.21C110.531 107.893 111.801 106.51 113.001 105.103L111.608 103.923L108.426 101.245L108.413 101.223Z"
                        fill="#121212"
                    />
                    <path
                        d="M89.4206 118.515L88.7406 117.062L88.5031 116.562L88.3456 116.232L87.0331 113.418L86.3656 112C83.6031 113.293 80.6931 114.37 77.7031 115.165L78.1006 116.675L78.8031 119.285L78.9956 120.035L80.0831 124.075L80.5581 125.835C84.1831 124.882 87.6806 123.578 91.0331 122.01L90.2506 120.342L89.4006 118.527L89.4231 118.518L89.4206 118.515Z"
                        fill="#121212"
                    />
                    <path
                        d="M2.06273 58.4576L5.77773 58.7751L11.2127 59.2526C11.4852 56.1426 12.0402 53.0926 12.8102 50.1401L11.3052 49.7426L9.55023 49.2651L8.52023 48.9926L7.54523 48.7326L5.88023 48.2776L3.94273 47.7551L2.16523 47.2776C1.21273 50.8401 0.557734 54.5276 0.240234 58.2951L2.07523 58.4426V58.4651L2.06273 58.4526V58.4576Z"
                        fill="#121212"
                    />
                    <path
                        d="M7.44258 43.265L7.95258 43.4475L9.45758 44.0025L11.0201 44.58L12.7176 45.205L14.1901 45.75C15.2651 42.7875 16.6126 39.94 18.1751 37.2525L13.5326 34.36C13.5326 34.36 13.5101 34.3825 13.5101 34.395L12.0376 33.475C12.0376 33.475 12.0151 33.51 12.0151 33.52L10.3176 32.465C10.3176 32.465 10.2951 32.4875 10.2951 32.5L8.73258 31.525C6.80758 34.8375 5.16508 38.31 3.83008 41.9525L5.56258 42.5875L7.43008 43.28L7.44258 43.2675V43.265Z"
                        fill="#121212"
                    />
                    <path
                        d="M17.9291 25.155L19.2541 26.2675L22.2316 28.765L23.4316 29.775C25.4016 27.415 27.5991 25.225 29.9666 23.25L28.9591 22.0475L27.7916 20.6525L26.7266 19.37L24.0541 16.1925L22.8766 14.775C20.0341 17.17 17.3966 19.8025 14.9941 22.6725L16.4091 23.8525L17.9391 25.135L17.9266 25.1575L17.9291 25.155Z"
                        fill="#121212"
                    />
                    <path
                        d="M29.5019 14.5351L30.4869 15.9526L32.7294 19.1301L33.6244 20.4226C39.8294 16.0326 47.0319 12.9801 54.8019 11.6176L54.5294 10.0751L54.2019 8.24759L53.9194 6.62509L53.1944 2.53009L52.8669 0.715088C43.4894 2.36009 34.7944 6.07009 27.2969 11.3701L28.3494 12.8801L29.5044 14.5251V14.5376L29.5019 14.5351Z"
                        fill="#121212"
                    />
                    <path
                        d="M20.6129 94.3125L19.3329 95.2075L17.8504 96.2525L16.4904 97.205L13.0929 99.6L11.5879 100.655C13.7279 103.717 16.1279 106.567 18.7679 109.2L20.0704 107.895L21.4854 106.477L22.7079 105.252L26.5704 101.382C24.3954 99.205 22.4029 96.8325 20.6354 94.3025L20.6129 94.315V94.3125Z"
                        fill="#121212"
                    />
                    <path
                        d="M94.3588 20.435L95.2538 19.1425L97.4963 15.965L98.4813 14.5475L99.6363 12.9125L100.689 11.4025C91.7313 5.0725 81.0863 0.9975 69.5688 0L69.0363 5.9675L68.7188 9.45H68.7413L68.5938 11.015C78.1187 11.8425 86.9288 15.2025 94.3463 20.4325H94.3588V20.435Z"
                        fill="#121212"
                    />
                    <path
                        d="M116.677 49.755L119.507 48.9825L120.039 48.8475L124.049 47.7575L125.827 47.28C122.872 36.2625 117.062 26.415 109.224 18.5725L105.012 22.7925L103.822 23.9725L102.542 25.255L101.432 26.3675C107.909 32.8575 112.734 41.015 115.179 50.1375L116.684 49.74L116.674 49.7525L116.677 49.755Z"
                        fill="#121212"
                    />
                    <path
                        d="M28.9614 105.737L27.7939 107.122L27.3514 107.645L26.4464 108.722L25.6089 109.732L24.0564 111.582L22.8789 113C25.7214 115.395 28.7789 117.527 32.0064 119.422L32.9239 117.835L33.9214 116.097L34.7814 114.6L35.3939 113.545L35.8464 112.772L36.7514 111.195L37.5439 109.845C34.8714 108.28 32.3464 106.497 29.9914 104.522L28.9839 105.725L28.9614 105.737Z"
                        fill="#121212"
                    />
                    <path
                        d="M14.5662 86.9825L15.9812 86.3125C15.3362 84.9275 14.7462 83.4875 14.2037 82.035L12.7312 82.58L11.0337 83.1925L9.47125 83.77L3.84375 85.8125C4.48875 87.5725 5.21375 89.295 5.99625 90.975L7.66125 90.215L9.76875 89.2275L11.0363 88.6375L14.5687 86.98L14.5662 86.9825Z"
                        fill="#121212"
                    />
                    <path
                        d="M4.735 74.3649L6.445 74.0599L10.285 73.3799L11.825 73.1074C11.305 70.1224 11.02 67.0724 11.02 63.9399L9.4575 63.9624V63.9399L7.645 63.9624V63.9399L5.98 63.9624V63.9399L0 64.0199C0 67.7649 0.3275 71.4399 0.9525 75.0374L2.765 74.7074L4.725 74.3549V74.3649H4.7375H4.735Z"
                        fill="#121212"
                    />
                </g>
                <g filter="url(#filter0_d_4129_25747)">
                    <rect x="5" y="29.5" width="118" height="68.5" rx="2" fill="#4D4D4D" />
                    <path
                        d="M62.884 51C63.2689 50.3333 64.2311 50.3333 64.616 51L83.8851 84.375C84.27 85.0417 83.7889 85.875 83.0191 85.875H44.4809C43.7111 85.875 43.23 85.0417 43.6149 84.375L62.884 51Z"
                        fill="#262626"
                    />
                    <path d="M62.5 74.3571V61.5H65.5V74.3571H62.5ZM62.5 81.5V78.6429H65.5V81.5H62.5Z" fill="#999999" />
                    <path d="M5 31.5C5 30.3954 5.89543 29.5 7 29.5H121C122.105 29.5 123 30.3954 123 31.5V41.5H5V31.5Z" fill="#999999" />
                    <path
                        d="M13 35.5C13 34.3954 12.1046 33.5 11 33.5C9.89543 33.5 9 34.3954 9 35.5C9 36.6046 9.89543 37.5 11 37.5C12.1046 37.5 13 36.6046 13 35.5Z"
                        fill="#262626"
                    />
                    <path
                        d="M20.0996 35.5C20.0996 34.3954 19.2042 33.5 18.0996 33.5C16.995 33.5 16.0996 34.3954 16.0996 35.5C16.0996 36.6046 16.995 37.5 18.0996 37.5C19.2042 37.5 20.0996 36.6046 20.0996 35.5Z"
                        fill="#262626"
                    />
                    <path
                        d="M27.0996 35.5C27.0996 34.3954 26.2042 33.5 25.0996 33.5C23.995 33.5 23.0996 34.3954 23.0996 35.5C23.0996 36.6046 23.995 37.5 25.0996 37.5C26.2042 37.5 27.0996 36.6046 27.0996 35.5Z"
                        fill="#262626"
                    />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_4129_25747" x="0" y="27" width="128" height="78.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.5" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4129_25747" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4129_25747" result="shape" />
                </filter>
                <clipPath id="clip0_4129_25747">
                    <rect width="128" height="128" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </div>
);

export default ErrorState;
