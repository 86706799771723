import {
  ColoredOverlay,
  Overlay,
  SectionPercentage,
  DataContainer,
  LeftSection,
  CenterTopSection,
  CenterBottomSection,
  RightSection,
  EyeTrackingImage,
  CenterSection,
  SectionText,
  Section
} from "./styles";
import { Title } from "../../session-detail.styles";
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../../components/ui/tooltip/tooltip';
import ContentLoader from '../../../../components/content-loader/content-loader';
import { CombinedChartsDataProps } from '../../api/fetchCombinedChartsData';

const getGazeEyeValue = (gazeEyeData: any, key: string) => {
  return gazeEyeData?.datasets[0]?.[key];
};

export const EyeTracking = ({combinedChartsData, isFetching}: CombinedChartsDataProps) => {

    // Filter the data to get the object with the code 'GAZE_EYE'
    const gazeEyeData = combinedChartsData?.find((item: any) => item.code === 'GAZE_EYE');
    if (gazeEyeData?.datasets.length === 0) return null;

    if (isFetching) return <ContentLoader height="26rem" />;

    return (
        <Section.Container>
          <Section.LandingScore>
            <Title>
              <FormattedMessage id={"session-detail:gaze-eye-tracking"} />
              <Tooltip text={gazeEyeData?.description} />
            </Title>
            <DataContainer>
              <EyeTrackingImage src="/assets/eye-tracking-bg.png" />
              <ColoredOverlay />
              <Overlay>
                <LeftSection>
                  <div>
                    <SectionText>
                     <FormattedMessage id={"session-detail:nearside"} />
                    </SectionText>
                    <SectionPercentage>{getGazeEyeValue(gazeEyeData, 'LEFT_MIRROR')}%</SectionPercentage>
                  </div>
                </LeftSection>
                <CenterSection>
                  <CenterTopSection>
                    <div>
                      <SectionText>
                        <FormattedMessage id={"session-detail:far-distance"} />
                      </SectionText>
                      <SectionPercentage>{getGazeEyeValue(gazeEyeData, 'TOP_MIRROR')}%</SectionPercentage>
                    </div>
                  </CenterTopSection>
                  <CenterBottomSection>
                    <div>
                      <SectionText>
                        <FormattedMessage id={"session-detail:near-distance"} />
                      </SectionText>
                      <SectionPercentage>{getGazeEyeValue(gazeEyeData, 'BOTTOM_MIRROR')}%</SectionPercentage>
                    </div>
                  </CenterBottomSection>
                </CenterSection>
                <RightSection>
                  <div>
                    <SectionText>
                     <FormattedMessage id={"session-detail:offside"} />
                    </SectionText>
                    <SectionPercentage>{getGazeEyeValue(gazeEyeData, 'RIGHT_MIRROR')}%</SectionPercentage>
                  </div>
                </RightSection>
              </Overlay>
            </DataContainer>
          </Section.LandingScore>
        </Section.Container>
    );
}