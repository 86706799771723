import { Input, Label, RadioWrapper, TitleAndRadioWrapper } from "./styles";
import { useEffect, useMemo, useState } from "react";
import { StyledRadioButton } from "../../../../components/ui/styled-radio";
import CombinedChart from "./combined-chart";
import { FormattedMessage } from "react-intl";
import { Title } from "../../session-detail.styles";
import { Container } from "./styles";
import { ChartData } from "../../../../types/combined-chart";
import Tooltip from "../../../../components/ui/tooltip/tooltip";
import ContentLoader from "../../../../components/content-loader/content-loader";
import { CombinedChartsDataProps } from "../../api/fetchCombinedChartsData";

type chartProps = {
  [k: string]: {
    label: string;
    color: string;
    disabled: boolean;
  } | undefined;
};

export type chartState = {
  [k: string]: boolean | undefined;
};

const TimelineChartDriving = ({combinedChartsData, isFetching}: CombinedChartsDataProps) => {

 const timelineDataSets: any = useMemo(() => combinedChartsData?.filter((dataSet:any) => dataSet.chartType === "Timeline"), [combinedChartsData]);
 const timelineChartData: ChartData[] = timelineDataSets?.flatMap((chart:any) => chart.datasets);

 const areaChartData: ChartData[] = useMemo(() => timelineChartData?.filter((chart: ChartData) => chart.type === "area"), [timelineChartData]);
 const scatterChartData: ChartData[] = useMemo(() => timelineChartData?.filter((chart: ChartData) => chart.type === "scatter"), [timelineChartData]);
 const annotationData: any = useMemo(() => timelineChartData?.filter((chart: ChartData) => chart.type === "rangeBar"), [timelineChartData]);

 const sortedDataForRadio = timelineChartData?.sort((a: ChartData,b: ChartData) => a?.position - b?.position);
 const chartTypes: chartProps = sortedDataForRadio?.reduce((acc:any, obj:any) => {
      acc[obj.name] = {
        label: obj.radioLabel,
        color: obj.color,
        disabled: obj.data.length === 0 ? true : false
      };
    return acc;
  }, {});

  const [chartStateByType, setChartStateByType] = useState<chartState>({});

  useEffect(() => {
    const chartState: chartState = {}
    for (let key in chartTypes) {
      chartState[key] = true
    }

    setChartStateByType(chartState);
  }, [combinedChartsData]);

  const changeHandler = (chartType: string) => {
    setChartStateByType((prevState) => ({
      ...prevState,
      [chartType]: !prevState[chartType]
    }));
  };

  if (isFetching) return <ContentLoader height="23rem" />;
  //if area chart data is empty, do not load component
  if (areaChartData.length === 0) return null

  return (
    <Container>

      <TitleAndRadioWrapper>
        <Title>
          <FormattedMessage id={"session-detail:timeline"} />
          <Tooltip text={timelineDataSets?.[0]?.description} />
        </Title>

        <RadioWrapper>
          {Object.entries(chartTypes).map(([chartType, chartProps]) => {
            const classNames = `
              ${chartStateByType[chartType] ? '' : 'unchecked'} 
              ${chartProps?.disabled ? 'disabled' : ''}`.trim();

            const testId = chartProps?.label.toLowerCase().replace(/\s+/g, '-');
            return(
              <Label key={chartType} htmlFor={chartType} className={classNames} data-test-id={testId}>
                <Input
                  type="checkbox"
                  defaultChecked
                  id={chartType}
                  disabled={chartProps?.disabled}
                  onChange={() => changeHandler(chartType)}
                />
                <StyledRadioButton
                  selected={!!chartStateByType[chartType]}
                  color={chartProps?.color}
                ></StyledRadioButton>
                {chartProps?.label}
              </Label>
            )}
          )}
        </RadioWrapper>
      </TitleAndRadioWrapper>

      <CombinedChart
        areaChartData={areaChartData}
        scatterChartData={scatterChartData}
        annotationData={annotationData}
        chartStateByType={chartStateByType}   
      />
    </Container>
  );
};

export default TimelineChartDriving;