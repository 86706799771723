import styled from "styled-components";
import base from "../../themes/base/base";

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    height: calc(100vh - var(--heat-header-height) - var(--sessions-page-header-height));
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    gap: 3rem;
    background-color: ${base.colors.neutral.grey[800]};
    align-items: center;
    border-radius: 0.5rem;
    width: 22rem;
    height: 22rem;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};
`;