import { useCallback, useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Page from "../../../../components/ui/page";
import { BiLoaderAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { Pages } from "../../../../enums/pages";

const UsersList = ({ height, users, onScrollHitBottom, loading }) => {

    const containerRef = useRef();
    const handleClick = useCallback(() => { }, []);


    useEffect(() => {
        if (loading) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;
        }

    }, [loading]);

    const handleScroll = useCallback((event) => {
        const { target } = event;

        if (target.scrollHeight - target.scrollTop === target.clientHeight && !loading) {
            onScrollHitBottom(event);
        }
    }, [onScrollHitBottom, loading]);

    return <div className="w-full">
        <div className="h-96 overflow-y-auto" onScroll={handleScroll} ref={containerRef}>
            <ul>
                {users.map((user, index) => {
                    return <li key={`users-list-${index}`} className="text-lg bg-gray-900 hover:bg-gray-800 my-2">
                        <a onClick={handleClick} className="w-full p-2 flex items-center justify-between cursor-pointer">
                            <div className="flex">
                                <span className="rounded-full border-white border-solid border-2 w-8 h-8 lh-8 mr-4 text-center">U</span>
                                {user.name}
                            </div>
                            <FiChevronRight fontSize="1.4rem" />
                        </a>
                    </li>
                })}
            </ul>
            {loading && <div className="bg-gray-800 py-6 flex justify-center text-gray-500 ">
                <BiLoaderAlt fontSize="2rem" className="animate-spin" />
            </div>}
        </div>
    </div>
};

const Back = () => {
    const history = useHistory();
    return <a className="flex cursor-pointer" onClick={() => history.push(Pages.AddAndAssign)}>
        <FiChevronLeft fontSize="1.4rem" className="mr-2" />Back
    </a>;
}

const AddTrainee = () => {

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([
        { name: 'John Doe' },
        { name: 'John Doe' },
        { name: 'John Doe' },
        { name: 'John Doe' },
        { name: 'John Doe' },
        { name: 'John Doe' },
        { name: 'John Doe' },
        { name: 'John Doe' },
        { name: 'John Doe' },
    ]);

    const handleScrollHitBottom = useCallback(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            setUsers([...users, ...[
                { name: 'John Doe' },
                { name: 'John Doe' },
                { name: 'John Doe' },
                { name: 'John Doe' },
                { name: 'John Doe' },
                { name: 'John Doe' },
                { name: 'John Doe' },
                { name: 'John Doe' },
                { name: 'John Doe' },
            ]]);
        }, 3000);
    }, [users]);

    const itemsAddTrainee = [{ label: "Add & Assign" }, { label: "Add trainee" }, { label: "Add existing" }]
    return <Page header={<Back />}>
        <h2 className="text-2xl font-bold mb-20">All Trainees</h2>
        <div className="mx-40">
            <UsersList users={users} onScrollHitBottom={handleScrollHitBottom} loading={loading} />
        </div>
    </Page>
};

export default AddTrainee;