import styled from "styled-components";
import base from "../../themes/base/base";

export const EmptyStateWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${base.colors.neutral.grey[800]};
    height: 100%;
`;

export const EmptyStateSecondaryWrapperDiv = styled.div`
    display: flex;
    margin: auto;
    min-height: 16.1875rem;
`;

export const EmptyStateContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.75rem;
`;

export const Title = styled.span`
    ${base.typography.extraLargeMedium};

    text-align: center;
`;

export const Description = styled.span`
    ${base.typography.baseRegular};

    text-align: center;
`;
