import { useQuery } from "react-query";
import { getCombinedCustomTopStats } from "../../../../api/sessions";
import useQueryParams from "../../../../utils/use-query-params";
import { CustomTopStat, TopStatProps } from "./top-stat";
import { TopStatWrapper } from './top-stats-styles';
import ContentLoader from "../../../../components/content-loader/content-loader";

const CustomTopStats = () => {
    const query:any = useQueryParams();

    const scenarioInstanceId = query.get("scenarioInstanceId");
    const simulationInstanceId = query.get("simulationInstanceId");
    const userId = query.get("userId");
    const from = query.get("from");
    const to = query.get("to");

    const { isFetching: isLoading, data } = useQuery(
        ["getCombinedCustomTopStats", userId, from, to, simulationInstanceId, scenarioInstanceId],
        () =>
        getCombinedCustomTopStats({ from, to, userId, simulationInstanceId, scenarioInstanceId }),
        {
          suspense: false,
          refetchOnMount: false,
          useErrorBoundary: true,
        }
      );
    
    if (isLoading) return <ContentLoader height="10.125rem" />
    if (!data) return null;
    const topStatsData = data?.sort((stat1:TopStatProps, stat2:TopStatProps) => stat1.position - stat2.position);

    return (
        <TopStatWrapper>
            {topStatsData.map((stat: TopStatProps, index: number) => <CustomTopStat {...stat} key={`${stat.code}-${index}`}/>)}
        </TopStatWrapper>
    )
};

export default CustomTopStats;
