import { useCallback, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
  Container,
  List,
  TraineesPopUp,
} from "../../../../components/filters/filters.styles";
import Checkbox, { CheckboxContainer } from "../../../../components/filters/multi-select/checkbox";
import { ArrowDownIcon } from "../../../../components/icons";
import { 
  ArrowIconContainer,
  SelectedCountSpan,
  StyledItem, 
  ToggleButton, 
  ToggleButtonContent,
  TextSpan,
  MultiSelectListContainer
} from "../../../../components/filters/multi-select/multi-select.styles";
import {
  Divider,
  FilterCheckedIcon,
  FilterRadioButton,
  GroupName,
  RoleContainer,
  StatusContainer
} from "./users-filter.styles";
import { RoleOptions, StatusOptions } from "../../../../enums/user";
import { CustomIcon } from "../../../onboarding/survey/survey.styles";
import { FormControlLabel, Radio } from "@mui/material";
import { FormattedMessage } from "react-intl";

export type Option = {
  id: string;
  label: string;
  value?: string | null;
};

type Props = {
  onRoleChange: (selected: Option[]) => void;
  style?: React.CSSProperties;
  title: string; // New prop for title
  icon: React.ReactElement; // New prop for icon
  showSelectAll?: boolean;
  onStatusChange: (status: string) => void;
};

export const UserMultiSelectDropdown = ({ onRoleChange, onStatusChange, title, icon, showSelectAll = false, ...props }: Props) => {
  const [selectedRoles, setSelectedRoles] = useState<Option[]>(RoleOptions);
  const [selectedStatus, setSelectedStatus] = useState<string | null>();
  const [isOpen, setIsOpen] = useState(false);
  const filterLength = selectedRoles.length + 1;

  useEffect(() => {
    if(selectedStatus) {
      onStatusChange(selectedStatus);
    }
  }, [selectedStatus]);

  const handleToggleList = useCallback(
    () => {
      setIsOpen(currentIsOpen => !currentIsOpen);
    },
    []
  );

  const handleOptionToggle = useCallback((option: Option) => {
    setSelectedRoles((prevSelected) => {
      const isSelected = prevSelected.some(({ id }) => id === option.id);
      const newSelected = isSelected
        ? prevSelected.filter(({ id }) => id !== option.id)
        : [...prevSelected, option];
        onRoleChange(newSelected);
      return newSelected;
    });
  }, [onRoleChange]);

  const clickOutsideRef = useDetectClickOutside({ onTriggered: () => {
    setIsOpen(false);
  }});

  const CustomRadio = <Radio checkedIcon={<FilterCheckedIcon />} icon={<CustomIcon className="radio-icon" />} />;

  return (
    <Container ref={clickOutsideRef} {...props}>
      <ToggleButton isOpen={isOpen} onClick={handleToggleList}>
        <ToggleButtonContent>
          {icon}
          <span>{title}</span>
          <SelectedCountSpan>{filterLength}</SelectedCountSpan>  
        </ToggleButtonContent>
        <ArrowIconContainer isOpen={isOpen}>
          <ArrowDownIcon />
        </ArrowIconContainer>
      </ToggleButton>
     
      {isOpen && (
        <TraineesPopUp width="100%">
          <MultiSelectListContainer>

            <RoleContainer>
              <GroupName><FormattedMessage id="users:filter:role" /></GroupName>
              <Divider />
              <List>
                {RoleOptions.map((option: Option) => (
                  <StyledItem key={option.id} isChecked={selectedRoles.some(({ id }) => id === option.id)} onClick={() => handleOptionToggle(option)}>
                    <CheckboxContainer>
                      <Checkbox
                        checked={selectedRoles.some(({ id }) => id === option.id)}
                        onChange={() => handleOptionToggle(option)}
                      />
                    </CheckboxContainer>
                    <TextSpan className={selectedRoles.some(({ id }) => id === option.id) ? 'checked' : ''}>
                      {option.label}
                    </TextSpan>
                  </StyledItem>
                ))}
              </List>
            </RoleContainer> 

            <StatusContainer>
              <GroupName><FormattedMessage id="users:filter:status" /></GroupName>
              <Divider />

              <FilterRadioButton
                  defaultValue='all'
                  value={selectedStatus}
                  onChange={(event) => {setSelectedStatus(event.target.value || null)}}
                >
                  <CheckboxContainer>
                    {StatusOptions.map((option: Option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.value}
                        control={CustomRadio} 
                        label={option.label}/>        
                    ))}
                  </CheckboxContainer>
                </FilterRadioButton>
            </StatusContainer> 

          </MultiSelectListContainer>
        </TraineesPopUp>
      )}
    </Container>
  );
};